/* ATTACHMENTS COLOUR OPTIONS */

$attachments-default-bg: $card-light-bg-color;
$attachments-default-filename-color: $primary;
$attachments-default-filename-color-hover: $bright_navy_blue;

@mixin attachments-default() {
	.view-attachments {
		.views-row {
			background:$attachments-default-bg;
			
			&:hover {
				.file-name {
					color:$attachments-default-filename-color-hover;
				}
			}
		}
	
		.attachment-link {	
			.file-name {
				color:$attachments-default-filename-color;
				
				&:hover {
					color:$attachments-default-filename-color-hover;
				}
			}
		}
	}
}

$attachments-alt-1-bg: $card-medium-bg-color;
$attachments-alt-1-filename-color: $primary;
$attachments-alt-1-filename-color-hover: $bright_navy_blue;

@mixin attachments-alt-1() {
	.view-attachments {
		.views-row {
			background:$attachments-alt-1-bg;
			
			&:hover {
				.file-name {
					color:$attachments-alt-3-filename-color-hover;
				}
			}
		}
	
		.attachment-link {	
			.file-name {
				color:$attachments-alt-1-filename-color;
				
				&:hover {
					color:$attachments-alt-1-filename-color-hover;
				}
			}
		}
	}
}

$attachments-alt-3-bg: $card-dark-bg-color;
$attachments-alt-3-bg-hover: $card-more-link-alt-3-hover-bg;
$attachments-alt-3-filename-color: $primary;
$attachments-alt-3-filename-color-hover: $bright_navy_blue;

@mixin attachments-alt-3() {
	.view-attachments {
		.views-row {
			background:$attachments-alt-3-bg;
			background-image: $hex-pattern-dark;
			background-size: $background-image-size;
			
			&:hover {
				background-color:$attachments-alt-3-bg-hover;
				
				.file-name {
					color:$attachments-alt-3-filename-color-hover;
				}
			}
		}
	
		.attachment-link {	
			.file-name {
				color:$attachments-alt-3-filename-color;
				
				&:hover {
					color:$attachments-alt-3-filename-color-hover;
				}
			}
		}
	}
}
