.wysiwyg-intro-text {
	color:$secondary;
	font: $title-font-family; 
}
.smaller-text {
	font-size: 0.750rem;
	line-height: 1.5;
}
.larger-text {
	// font-size: 1.250rem;
	// line-height: 1.3;
		font: $section-row-title-font-smaller;

}