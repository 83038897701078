
.page-node-80 {

	.content-main {
		h1 {
			font: $card-title-font; 
		}
		
	}
	.view-filters {
			@include span-columns(7);
			background-color: $platinum;
			padding: 1rem;
			.views-exposed-widget{
				margin-right: 1rem;
			}
			.views-widget {
				.form-item {
					input {
						color: $dark_liver;
						font: $header-search-field-font;
						border: $header-search-field-border;
						margin-top: 1rem;
						padding: 1/4rem;
						width: 200px;
					}
				}
				.form-select {
					color: $dark_liver;
					font: $header-search-field-font;
					border: $header-search-field-border;
					margin-top: 1rem;
					padding: 1/4rem;
					&:after {
						background:$arrow-teal-down no-repeat scroll center center transparent;
						background-size:contain;
						width: 16px;
					}
				}
			}
	}

}