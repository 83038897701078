.node-type-person {
	
	.node-inner {
		overflow: hidden;
	}
	
	.card-suffix {
		display: inline;
	}
	
	.card-profile-details {
		margin-bottom: $vert-space;
		text-align: center;
		.card-photo img {
			max-width: 360px;
			width: 100%;
		}		
		@include media($narrow) {
			float: left;
			max-width: 240px;
			margin-right: $horz-space*2;
		}
		.card-links {
			background-color: $card-light-bg-color;
			padding: 20px 20px 5px;
			text-align: left;
			display: none;
			@include media($narrow) {
				display: block;
			}
		}
	}

	.card-profile-details-mobile {
		display: block;	
		.card-links {
			background-color: $card-light-bg-color;
			padding: 20px 20px 5px;
			text-align: left;
		}
		@include media($narrow) {
			display: none;
		}
	}
	
  .card-links ul li,
  .card-links {
    a /* Unidentified */ {
      background:$ico-web no-repeat 0 50% / 16px 16px;
      color:$action-default;
      display: block;
      line-height: 1.25;
      margin:0 0 0.75em 0;
      overflow: hidden;
      padding:0 0 0 2em;
      word-wrap:break-word;
     
       &:hover {
         background: $ico-web-over no-repeat 0 50% / 16px 16px;
         color:$action-hover;
       }
    }
  }

  .card-links /* Institutional profile */ {
    .institution a,
    a[href*=".ac.uk"],
    a[href*=".edu"] {
      background: $ico-institution no-repeat 0 50% / 15px 15px transparent;
      
        &:hover {
          background: $ico-institution-over no-repeat 0 50% / 15px 15px transparent;
        }
    }
  }

  .card-links /* Research Gate */ {
    .research-gate a,
    a[href*="researchgate.net"],
    a[href^="https://www.researchgate.net"],
    a[href^="http://www.researchgate.net"],
    a[href^="https://researchgate.net"],
    a[href^="http://researchgate.net"] {
          background: $ico-researchgate no-repeat 0 50% / 16px 17px transparent;
      
        &:hover {
          background: $ico-researchgate-over no-repeat 0 50% / 16px 17px transparent;
        }
    }
  }
  
  .card-links /* Email */ {
    .email a,
    a[href^="mailto:"],
    a[href*="@"] {
      background: $ico-email no-repeat 0 50% / 14px 15px transparent;
      
      &:hover {
        background: $ico-email-over no-repeat 0 50% / 14px 15px transparent;
      }
    }
  }
  
  .card-links /* Google Scholar */ {
    .g-scholar a,
    a[href*="scholar.google"] {
    background: $ico-gscholar no-repeat 0 50% / 15px 17px transparent;
    
      &:hover {
        background: $ico-gscholar-over no-repeat 0 50% / 15px 17px transparent;
      }
    }
  }
  
  .card-links /* linkedin */ {
    .linkedin a,
    a[href*="linkedin.com"],
    a[href^="https://www.linkedin.com"],
    a[href^="http://www.linkedin.com"],
    a[href^="https://linkedin.com"],
    a[href^="http://linkedin.com"] {
      background: $ico-linkedin no-repeat scroll 0 0 / 17px 14px transparent;
      
      &:hover {
        background:$ico-linkedin-over no-repeat scroll 0 0 / 17px 14px transparent;
      }
    }  
  }
  
  .card-links /* twitter */ {
    .twitter a,
    a[href*="twitter.com"],
    a[href^="https://www.twitter.com"],
    a[href^="http://www.twitter.com"],
    a[href^="https://twitter.com"],
    a[href^="http://twitter.com"] {
      background: $ico-twitter no-repeat scroll 0 50% / 17px 17px;
      
      &:hover {
        background: $ico-twitter-over no-repeat scroll 0 50% / 17px 17px;
      }
    }
  }
  
  .card-links /* facebook */ {
    .facebook a,
    a[href*="facebook.com"],
    a[href^="https://www.facebook.com"],
    a[href^="http://www.facebook.com"],
    a[href^="https://facebook.com"],
    a[href^="http://facebook.com"] {
      background: $ico-facebook no-repeat scroll 0 50% / auto 17px ;
    
      &:hover {
        background: $ico-facebook-over no-repeat scroll 0 50% / auto 17px;
      }
    }
  }
  
  .card-links /* google+ */ {
    .g-plus a,
    a[href*="plus.google.com"],
    a[href^="https://www.plus.google.com"],
    a[href^="http://www.plus.google.com"],
    a[href^="https://plus.google.com"],
    a[href^="http://plus.google.com"] {
      background:$ico-gplus no-repeat scroll 0 50% / 19px 17px transparent;
      
      &:hover {
        background: $ico-gplus-over no-repeat scroll 0 50% / 19px 17px transparent;
      }
    }
  }
  
  .card-links /* orcid */ {
    .orcid a,
    a[href*="orcid.com"],
    a[href^="https://www.orcid.com"],
    a[href^="http://www.orcid.com"],
    a[href^="https://orcid.com"],
    a[href^="http://orcid.com"] {
      background:$ico-orcid no-repeat scroll 0 0 / 14px 14px transparent;
      
      &:hover {
        background: $ico-orcid-over no-repeat scroll 0 0 / 14px 14px transparent;
      }
    }
  }
  
  .card-links /* YouTube */ {
    .youtube a,
    a[href*="youtube.com"],
    a[href^="https://www.youtube.com"],
    a[href^="http://www.youtube.com"],
    a[href^="https://youtube.com"],
    a[href^="http://youtube.com"] {
      background:$ico-youtube no-repeat scroll 0 50% / 18px auto transparent;
      
      &:hover {
        background: $ico-youtube-over no-repeat scroll 0 50% / 18px auto transparent;
      }
    }
  }
  
  .card-links /* flickr */ {
    .flickr a,
    a[href*="flickr.com"],
    a[href^="https://www.flickr.com"],
    a[href^="http://www.flickr.com"],
    a[href^="https://flickr.com"],
    a[href^="http://flickr.com"] {
      background:$ico-flickr no-repeat scroll 0 50% / 18px auto transparent;
      
      &:hover {
        background: $ico-flickr-over no-repeat scroll 0 50% / 18px auto transparent;
      }
    }
  }
}