.user-profile {
	h1#page-title {
		visibility: hidden;
		height: 0;
		font-size: 0;
		line-height: 0;
	}
	.card-honorific-title,
	.card-first-name,
	.card-surname {
		display: inline-block;
		font-size:2rem;
		line-height:1.1875;
		margin-bottom:$vert-space/4;
		
		@include media($normal) {
			line-height: 1.33333333333333;
		}
	}
	
	article {
		background:white;
		overflow: hidden;
		padding: $card-text-padding;
		transition: $card-transition;
		
		@media (max-width:539px) {
			display: flex;
			flex-direction: column;
		}

	}
	
	aside  {
		margin-bottom: $vert-space;
		@media (max-width:539px) {
			order: 2;
		}

		@include media(540px) {
			@include span-columns(4);
			float: right;
			margin-left: $horz-space;
			margin-right: 0;
			min-width: 240px;
		}
	}
			
	.profile-inner {
		@media (max-width:539px) {
			order: 1;
			padding-bottom: $vert-space;
		}
	}
	
	.card-gnat-user-photo {
		margin: 0;
	}
	
	.card-gnat-person-er a {
		@include button-states ($button-bg, $button-text, $button-border-color);
		display: block;
		margin: $vert-space/2 0;
		padding: $vert-space/4 $horz-space;
	}
	
	.label {
		display: inline-block;
	}
	
	
}