// MENU FLASH FIX
// --- Set height for menu here to ensure menu loading causes minimal fuss
#site-header {
	.block-menu-block {
		display: none;
	}
	.main-nav-wrapper,
	.block-menu-block {
		
		@media (min-width:880px) and (max-width:1150px) {
			min-height:46px;
		}

		@include media(1150px) {
			min-height:46px;
		}
	}
}

// MENU STYLES
#site-header {					
	@include media(880px) {
		.main-nav-wrapper {
			background: $nav-wrapper-bg-color;
		}
	}
	.block-menu-block {
		clear:right;
		position: relative;
		
		.main-menu-btn {
			//background-color:$nav-button-bg-color;
			float:right;
			width:$nav-button-width;
			height:$nav-button-height;
			text-indent:-999em;
			//margin: -74px 0px 0px 0px;
			margin: 31px 0px 0px 0px;
						
			.main-menu-btn-icon {
 				left:16px;
				height:$nav-button-bar-height;
				width:$nav-button-bar-width;
				background-color:$nav-button-bar-bg-color;
				border-radius:$nav-button-bar-border-radius;
				margin-top:-2px;
				
				&:before {
					top:-14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
				
				&:after {
					top:14px;
					left:0;
					height:$nav-button-bar-height;
					width:$nav-button-bar-width;
					background-color:$nav-button-bar-bg-color;
					border-radius:$nav-button-bar-border-radius;
				}
			} 
		}
		
		ul.sm {
			background: none; 
			clear:both;
		}
		
		@include media(880px) { 
			clear:right;
			float:right;
		}		
		
		.menu-block-wrapper ul.menu.sm  {
			
			border:none;
			box-shadow:none;
					
			& > li {
				border-left:none;
				flex-grow: 1;
				text-align: center;

				&:last-child { 
					position:relative;
				}

				@include media(880px) { 
					& > a > .sub-arrow {
						display:none;
					}
				}

				& > a {
					background-color: $nav-base-bg-color;
					text-align: center;
					font:$nav-base-font;
					color:$nav-base-text-color;
					padding:$nav-base-padding;
					text-transform: uppercase;

					@media (min-width:880px) and (max-width:1150px) {
						font: $nav-base-font-smaller;
						padding: $nav-base-padding-smaller;
					}

					&:hover {
						border-bottom: $nav-hover-border;
						padding:$nav-base-hover-padding;
						background-color:$nav-hover-bg-color;
						color:$nav-hover-text-color;

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}
					}
										
					/* Link to current page */		
					&.active.current,
					&.active-trail.current {
						background-color:$nav-active-bg-color;
						color:$nav-active-text-color;
						border-radius:$nav-active-border-radius;
						
						&:hover {
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;
						}
					}

					&.highlighted {
						background-color:$nav-highlight-bg-color;
						color:$nav-highlight-text-color;
						border-bottom: $nav-highlight-border;
						border-top: none;
						padding:$nav-base-hover-padding;

						@media (min-width:880px) and (max-width:1150px) {
							padding:$nav-base-hover-padding-smaller;
						}

						&:hover {
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;
						}
					}
				}
			}
			
			li {
				text-transform: normal;
				
				a {
					cursor:pointer; 
					background-color: transparent;
										
					.sub-arrow {
						background:$arrow-teal-right no-repeat scroll center center transparent;
						background-size:contain;
						width: 16px;
						text-indent:-999em;
						overflow:hidden;
						right:0;
					}
				}
				
				ul.menu {	
					background:$nav-sub-bg-color;
					border:$nav-sub-border;
					border-radius:$nav-sub-border-radius;
					box-shadow:$nav-sub-box-shadow;
					
					li {
						border-top:none; 
						
						a {
							color:$nav-sub-text-color;
							font: $nav-sub-base-font;
							line-height:1.225;
							padding:$nav-sub-base-padding;
							
							&:hover, 
							&.active {
								background-color:$nav-sub-hover-bg-color;
								color:$nav-hover-text-color;
							} 
							
							.sub-arrow {
								background:$arrow-grey-right no-repeat scroll center center transparent;
								background-size:contain;
								text-indent:-999em;
								overflow:hidden;
								right:10px;				
							}	
							
							&.active {
								.sub-arrow {
									background:$arrow-white-right no-repeat scroll center center transparent;
									background-size:contain;
								}
							}
							
							&:hover, 
							&.active:hover  {
								.sub-arrow {
									background:$arrow-white-right no-repeat scroll center center transparent;
									background-size:contain;
								}								
							}		
							
							&.active.current {
								background-color:$nav-active-bg-color;
								color:$nav-active-text-color;
								
								&:hover {
									background-color:$nav-hover-bg-color;
									color:$nav-hover-text-color;
								}								
							}					
						}						
						&.expanded {
							
							& > a.highlighted { 
								background-color:$nav-hover-bg-color;
								color:$nav-hover-text-color;

								.sub-arrow {
									background:$arrow-white-right no-repeat scroll center center transparent;
									background-size:contain;
								}

								&:hover, 
								&.current.active {
									background-color:$nav-sub-hover-bg-color;
									color:$nav-hover-text-color;
								}
							}
						}
					}
				}
			}
			
		}	
		
////////////////////////////////////      MOBILE      ////////////////////////////////////
//
//		The Structure is separated by the first menu and then the next levels, with just a few clases to control the mobile menu:
//
//		li.expanded = <li> with sub menu. Fixed class from the library, it never changes to collapsed or other
//		li.active-trail = is part of the path to the active btn
//		a.active-trail or/+ .current = is part of the path to the active btn
//		.highlighted = expanded menu
//		.active = selected page that you are visualizing 
//
//		Mostly all the main configuration comes from _var-config with all the variables for the colours, apart of that there are the arrows and some borders to change manually.
		
		
		@media (max-width:879px) {
		
			#main-menu-state:checked ~ .main-menu-btn { background:$nav-button-hover-bg-color;} //margin: 0 0 1px; height: 75px;}
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon { height:$nav-button-bar-height; margin-top: -4px; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before,
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:0; transform:none; background-color:$nav-button-bar-hover-bg-color; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before { top:-14px; }
			#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:14px; }


			.menu-block-wrapper ul.menu.sm {
				.sub-arrow {
					margin-right:10px;
				}
				
				//border: $nav-mob-border;
				//border-top: none; 
				box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
				
				position: absolute;
				right: 0;
				width: calc(100% - 5%); 
				max-width: 400px;
				z-index: 1005;
				top: 112px; //6px;
				
				& > li {
					
					&.first.leaf {
						//border: none;
						//border-top:$nav-mob-expanded-border;
					}
					&.first.first {
						border: none;
					}
					
					a .sub-arrow {
						width: 34px;
					}

					///////////////////////////////      1st LEVEL      ///////////////////////////////
					& > a {
						background-color:$nav-mob-1st-bg-color;
						text-align: left;
						font:$nav-base-font;
						color:$nav-sub-text-color;
						padding:$nav-mob-padding;
						text-transform: uppercase;

						&:hover {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							padding:$nav-mob-padding;
							
							.sub-arrow {
								background:$arrow-white-right no-repeat scroll center center transparent;
								background-size:$nav-arrow-right-size;
							}	
						}

						&.current { 
							background:$nav-mob-1st-bg-color;
							color:$nav-mob-text-color;
							padding:$nav-mob-padding;
							border-top:$nav-mob-expanded-border;
							
							.sub-arrow {
								background:$arrow-teal-right no-repeat scroll center center transparent;
								background-size:$nav-arrow-right-size;
							}
							
							&:hover {						
								background-color:$nav-sub-hover-bg-color;
								color:$nav-sub-hover-text-color;
								.sub-arrow {
									background:$arrow-white-right no-repeat scroll center center transparent;
									background-size:$nav-arrow-right-size;
								}
							}
							
							&.active-trail {						
								.sub-arrow {
									background:$arrow-white-right no-repeat scroll center center transparent;
									background-size:$nav-arrow-right-size;
								}
								
								&.highlighted {
									.sub-arrow {
										background:$arrow-white-down no-repeat scroll center center transparent;
										background-size:$nav-arrow-down-size;
									}
								}
							}
						}

						&.active { 
							background-color: $nav-mob-1st-highlight-bg-color;
							color: $nav-mob-active-text-color;
							.sub-arrow {
								background:$arrow-white-right no-repeat scroll center center transparent;
								background-size:$nav-arrow-right-size;
							}
							&:hover {
								.sub-arrow {
									background:$arrow-white-right no-repeat scroll center center transparent;
									background-size:$nav-arrow-right-size;
								} 
							}
						}

						&.highlighted {
							background-color:$nav-mob-1st-highlight-bg-color;
							color:$nav-mob-1st-highlight-text-color;
							padding:$nav-mob-padding;
							
							// LEVEL BORDERS: TOP  //
							border-top: 1px solid $nav-mob-1st-border-color;
							
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center transparent;
								background-size:$nav-arrow-down-size;						
							}
							&:hover {
								background-color:$nav-mob-1st-highlight-bg-color ;
								color:$nav-mob-1st-highlight-text-color;
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:$nav-arrow-down-size;
								}	
							}
						}

						&.active-trail{
							background-color: $nav-mob-active-trail-bg-color;//$nav-mob-1st-bg-color;
							color: $nav-mob-active-trail-text-color;//$nav-mob-text-color;
							border-bottom: $nav-highlight-border;
							padding:$nav-mob-padding;

							&:hover {
								background-color:$nav-mob-1st-highlight-bg-color;
								color:$nav-hover-text-color;
							}
							
							&.highlighted {
								background-color:$nav-mob-active-trail-bg-color;
								color:$nav-mob-1st-highlight-text-color;
							}
						}
						
						&.active-trail.active.current.highlighted {
							background-color:$nav-mob-active-trail-bg-color;
							color:$nav-mob-active-trail-text-color;
						}
						
						&.active-trail.current.highlighted {
							&:hover {
								background-color:$nav-mob-active-trail-bg-color;
							}
						}
					}
				}
				
				li {
					border: none;
					
					a {
						border-top: $nav-mob-border;
						border-left: none;
						padding:$nav-mob-padding;
						
						color: $nav-mob-text-color;
						.sub-arrow {
							background:$arrow-teal-right no-repeat scroll center center transparent;
							background-size:$nav-arrow-right-size;
							
							height: 46px;
							margin-top: -23px;
							//border-left: $nav-mob-border; 
						}
					}
					
					&.first a {
						border-top: none;
					}
					
					
					///////////////////////////////      NEXT LEVELS      ///////////////////////////////
					
					/* ul BACKGROUNDS + BORDERS */
					&.expanded {
						// 2nd level //
						ul.menu,
						ul.menu a.current {
							background-color: $nav-mob-2nd-bg-color;
							border-top: 1px solid $white;
							
							&.active-trail {
								background-color: $nav-mob-2nd-highlight-bg-color;
								&.active {
									background-color: $nav-mob-active-bg-color;
									color: $nav-mob-active-text-color;						
								}
								&.active.current {
									.sub-arrow {
										background:$arrow-white-right no-repeat scroll center center transparent;
										background-size:$nav-sub-arrow-right-size;
									}					
								}
								&:hover {
									background-color: $nav-sub-hover-bg-color;
								}
							}
							
							li.first a {
								border-top:none;
							}

							li:not(.first) a {
								border-top: 1px solid $nav-mob-2nd-line-color;
							}
							
							// LEVEL BORDERS: BOTTOM (TOP: Look 2nd level a.highlighted)  //
							li.last {					
								border-bottom: 1px solid $nav-mob-1st-border-color;
								margin-bottom: -1px;
								z-index: 1;
								position: relative;
							}
							
							a {
								border-left: none;
								padding:$nav-mob-padding;						
							
								&.active {
									background-color: $nav-mob-active-bg-color;
									color: $nav-mob-active-text-color;						
								}
								
								&.current {
									background-color: $nav-mob-2nd-bg-color ;
									color: $nav-sub-text-color;	
									
									.sub-arrow {
										background:$arrow-grey-right no-repeat scroll center center transparent;
										background-size:$nav-sub-arrow-right-size;
									}	
									
									&:hover {
										background-color: $nav-sub-hover-bg-color;
										color: $nav-sub-hover-text-color;
										
										.sub-arrow {
											background:$arrow-white-right no-repeat scroll center center transparent;
											background-size:$nav-sub-arrow-right-size;
										}	
									}			
								}
							}

							li.expanded {
								
								// 3rd level //
								ul.menu {	
									background-color: $nav-mob-3rd-bg-color;
									border-top: 1px solid $nav-mob-2nd-border-color;
									border-bottom: 1px solid $nav-mob-2nd-border-color;	

									li.first a {
										border-top:none;
									}

									li:not(.first) a {
										border-top: 1px solid $nav-mob-3rd-line-color;
									}
							
									// LEVEL BORDERS: BOTTOM (TOP: Look 3rd level a.highlighted)  //
									li.last {					
										border-bottom: 1px solid $nav-mob-2nd-border-color;
										z-index: 2;
									}
							
									a {
										border-left: none;
										padding:$nav-mob-padding;
										padding-left:2rem;								

										&.active {
											background-color: $nav-mob-2nd-highlight-bg-color;
											color: $nav-mob-2nd-highlight-text-color;						
										}
										
										&.current {
											background-color: $nav-mob-3rd-bg-color ;
											color: $nav-sub-text-color;	
											
											.sub-arrow {
												background:$arrow-grey-right no-repeat scroll center center transparent;
												background-size:$nav-sub-arrow-right-size;
											}	
											
											&:hover
											{
												.sub-arrow {
													background:$arrow-white-right no-repeat scroll center center transparent;
													background-size:$nav-sub-arrow-right-size;
												}
											}						
										}
									}
								}

								/* li.expanded {
									
									// 4th level //
									ul.menu {	
										background-color: $nav-mob-4th-bg-color;	
										border-top: 1px solid $nav-mob-3rd-border-color;	

										li.first a {
											border-top:none;
										}

										li:not(.first) a {
											border-top: 1px solid $nav-mob-4th-line-color;
										}
										
										// LEVEL BORDERS: BOTTOM (TOP: Look 4th level a.highlighted)  //
										li.last {				
											border-bottom: 1px solid $nav-mob-3rd-border-color;
											z-index: 3;											
										}
							
										a {
											border-left: none;
											padding:$nav-mob-padding;								

											&.active {
												background-color: $nav-mob-active-bg-color;
												color: $nav-mob-active-text-color;
											}
										}
									}
								} */
							}
						}
					}
					
					/* ARROWS + HIGHLIGHT */ 
					ul.menu {	
						box-shadow: none;
						
						li {
							//border-top:$nav-mob-expanded-border;
							border-top: none;
							
							a {
								color: $primary;
								.sub-arrow {
									background:$arrow-grey-right no-repeat scroll center center transparent;
									background-size:$nav-sub-arrow-right-size;
									right:0;
									height: 39px;
									margin-top: -19px;
									//border-left: $nav-mob-border;
								}	
															
								&.active {
									.sub-arrow {
										background:$arrow-grey-right no-repeat scroll center center transparent;
										background-size:$nav-sub-arrow-right-size
									}
								}

								&:hover, 
								&.active:hover  {
									.sub-arrow {
										background:$arrow-white-right no-repeat scroll center center transparent;
										background-size:$nav-sub-arrow-right-size
									}								
								}
							}
							
							&.expanded {							
								ul.menu a.current {
									background-color: $nav-mob-2nd-highlight-bg-color;

									&.active {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;
										.sub-arrow {
											background:$arrow-grey-down no-repeat scroll center center transparent;
											background-size:$nav-sub-arrow-down-size;
										}
									}

									&:hover {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-down no-repeat scroll center center transparent;
											background-size:$nav-sub-arrow-down-size;
										}
									}
								}
								
								////////////**     2nd LEVEL     **////////////
								& > a.highlighted { 
									background-color:$nav-mob-2nd-highlight-bg-color;
									color:$nav-mob-2nd-highlight-text-color;
									
									// LEVEL BORDERS: TOP  //
									border-top: 1px solid $nav-mob-2nd-border-color;

									.sub-arrow {
										background:$arrow-grey-down no-repeat scroll center center transparent;
										background-size:$nav-sub-arrow-down-size;
									}

									&:hover, &.current {
											background-color:$nav-mob-2nd-highlight-bg-color;
											color:$nav-sub-text-color;
									
											.sub-arrow {
												background:$arrow-grey-down no-repeat scroll center center transparent;
												background-size:$nav-sub-arrow-down-size;
											}
									}
									 
									&.current.active {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-down no-repeat scroll center center transparent;
											background-size:$nav-sub-arrow-down-size;
										}
									}
									
									&.current.active.active-trail {
										background-color:$nav-mob-active-trail-bg-color;
										color:$nav-mob-active-trail-text-color;

										.sub-arrow {
											background:$arrow-white-down no-repeat scroll center center transparent;
											background-size:$nav-sub-arrow-down-size;
										}
									}	
								}

								////////////**     3rd LEVEL     **////////////
								& > ul.menu > li.expanded > a.highlighted { 
									background-color: $nav-mob-3rd-highlight-bg-color;
									color: $nav-mob-3rd-highlight-text-color;
									
									// LEVEL BORDERS: TOP  //
									border-top: 1px solid $nav-mob-3rd-border-color;

									.sub-arrow {
										background:$arrow-grey-down no-repeat scroll center center transparent;
										background-size:$nav-sub-arrow-down-size;
									}
									
									&.current, &:hover {
											background-color:$nav-mob-3rd-highlight-bg-color;
											color:$nav-sub-text-color;
									
											.sub-arrow {
												background:$arrow-grey-down no-repeat scroll center center transparent;
												background-size:$nav-sub-arrow-down-size;
											}
									}
 
									&.current.active {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-down no-repeat scroll center center transparent;
											background-size:$nav-sub-arrow-down-size;
										}
									}	
									
									&.current.active.active-trail {
										background-color:$nav-mob-active-trail-bg-color;
										color:$nav-mob-active-trail-text-color;

										.sub-arrow {
											background:$arrow-white-down no-repeat scroll center center transparent;
											background-size:$nav-sub-arrow-down-size;
										}
									}							
								}

								////////////**     4th LEVEL     **////////////
								/* & > ul.menu > li.expanded > ul.menu > li.expanded > a.highlighted { 
									background-color: $nav-mob-4th-highlight-bg-color;
									color: $nav-mob-4th-highlight-text-color;
									
									// LEVEL BORDERS: TOP  //
									border-top: 1px solid $nav-mob-4th-border-color;

									.sub-arrow {
										background:$arrow-grey-down no-repeat scroll center center transparent;
										background-size:50%;
									}
									
									&.current {
											background-color:$nav-active-bg-color;
											color:$nav-active-text-color;
									
											.sub-arrow {
												background:$arrow-white-down no-repeat scroll center center transparent;
												background-size:50%;
											}
											
											&:hover {
												.sub-arrow {
													background:$arrow-white-down no-repeat scroll center center transparent;
													background-size:50%;
												}
											}
									}
									
									&:hover, 
									&.current.active {
										background-color:$nav-sub-hover-bg-color;
										color:$nav-hover-text-color;

										.sub-arrow {
											background:$arrow-white-down no-repeat scroll center center transparent;
											background-size:50%;
										}
									}								
								} */
							}
						}
					}
				}
			}
		}
	}
}