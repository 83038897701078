/*.i18n-en .site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }
.i18n-fr .site-title a { background:$logo-fr no-repeat scroll 0 0 transparent; }
.i18n-pt .site-title a { background:$logo-pt no-repeat scroll 0 0 transparent; }*/
.site-title a { 
	background : url("../images/logo.png") no-repeat scroll 0 0 transparent; 
	float:left;	
}

#site-header {
	background-color:$header-bg-color;
	border-bottom:6px solid $light_sea_green;
	@include clearfix;
	//
	//
	// /* Change .header-outer-wrapper to 
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	//
	.header-outer-wrapper {
		position:relative;
		padding:0  10px;
		@include outer-container($site-max-width);
	}
	.site-branding {
		margin-top:2.25rem;
		margin-left:1.25rem;
		position: relative;
		@include media(880px) {
			margin-top:1.25rem;
			top:0;
		}
		.site-title {
			position:relative;
			left:0;
			top:2.5rem;
			top:0rem;
			a {
				background-size:contain;
				/*display:block;*/
				text-indent:-999em;
				overflow:hidden;
				width: 200px;
				height: 95px;
				margin-bottom: 10px;
				
				@include media(880px) { 
					width:260px;
					height:124px;
					margin-bottom: 0;
				}
			}
		}
		.site-slogan {
			position:absolute;
			left:235px;
			top:2.5rem;
		}
	}

	@media (max-width:880px) {
		.main-nav-wrapper {
			margin-top: 0; //104px;
		}
	}
	
	.wrapper-region-header{
		display: block;
		vertical-align: top;
		float: right;
		margin-top: 10px;
		
		@include media(880px) {
			margin-bottom:3rem;
		}
	}
	
	.block-search {
		color: $taupe_gray;
		margin-top: 2px;
		display: none;
		@include media(880px) {
			height:auto;
			display: inline-block;
		}
		h2 {
			font-family:$base-font-family;
			font-size:1rem;
			color:$primary;
			float:left;
			line-height:1.5;
			margin-top:1rem;
			margin-right:0.75rem;
			cursor:pointer;
			display:none;
			@include media(880px) {
				margin-right:2.5rem;
			}
		}
			.form-wrapper:not(.form-actions) {
				display: inline-block;
				width:auto;
				height:38px;
				position:relative;
				bottom:5px;
				vertical-align: top;
				border:$header-search-field-border;
				border-radius:$header-search-field-border-radius;
				padding:0.25rem 0.75rem;
				background-color:$header-search-field-bg-color;
			}
		.form-item-search-block-form {
			position: relative;
			bottom: 12px;
			left: 22px;
			
			@media(max-width:$normal) {
				bottom: 21px;
			}
		
			margin-top:0.75rem;
			margin-right:0.75rem;
			margin-bottom:0;

			@include media(880px) {
				//margin-bottom:3rem;
			}
			@include media($wide) {
				//margin-bottom:3rem;
			}
			/* Search placeholder */
			::-webkit-input-placeholder {
		    	color:$header-search-field-text-color;
		    	opacity:1;
			}
		
			::-moz-placeholder {
		    	color:$header-search-field-text-color;
		    	opacity:1;
			}
		
			::-ms-placeholder {
		    	color:$header-search-field-text-color;
		    	opacity:1;
			}
		
			::placeholder {
		    	color:$header-search-field-text-color;
		    	opacity:1;
			}
			/**/

			input.form-text {
				//float: right;
			-webkit-appearance: none;
				
				width:10rem;
				height:1.875rem;
				background-color:transparent;
				color:$header-search-field-text-color;
				box-shadow:$header-search-field-box-shadow;
				border:none;
				border-radius:0;
				font:$header-search-field-font;
				padding:0;
				display: inline-block;
			}
		}
		.form-actions {
			position: relative;
			bottom: 13px;
			left: -176px;
			
			@media(max-width:$normal) {
				bottom: 22px;
			}
			
			//float:left;
			margin-top:0.75rem;
			display: inline-block;
			@include media(880px) {
				//margin-bottom:5rem;
			}
			input.form-submit {
				//float: left;
				background: transparent $search no-repeat scroll 0 0;
				background-size:14px;
				width:0.875rem;
				height:0.875rem;
				text-indent:-999em;
				overflow:hidden;
				padding:0;
			//	position:absolute;
			//	right:1.25rem;
				//top:1.25rem;
			}
		}
	}

	
	.block-menu {
		//order: 1;
		margin-top:0;
		padding-top: 0;
		@include media(880px) {
			height:auto;
			display: inline-block;
		}

		
		@media (max-width:880px) {
			position: absolute;
			top: -2.75rem;
			right: 0;
		}
		
		@include media(880px) {
			margin-right:0.5rem;
			margin-bottom:0;
		}
		ul {
			//margin-: 20px;
			@include media(880px) {
				margin: 0;				
			}
		}
		li {
			display:inline-block;
			margin:0 1.125rem 0 0;
			
			&.last {
				@include media(880px) {
					display: none;
				}
			}
		}
		
		a {
			font:$header-menu-font;
			color:$primary;
			&:hover {
				color:$light_sea_green;
			}
		}
	}

	.block-gtranslate {
		display: none;
		@include media(880px) {
			display: inline-block;
		}

		::-ms-expand {
   			display: none;
		}
		
		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			-ms-appearance: none;
			-o-appearance: none;
			appearance:none;
			
			color: $dark_liver;
			font: $header-search-field-font;
			vertical-align: top;
			width: 12rem;
			border: $header-search-field-border;
			background:$arrow-iceberg-dropdown no-repeat scroll 94% 50% white;
			margin-right: 20px;
		}

	}

	.block-locale {
		clear:both;
		display:none;
	}
}