/* SLIDER OPTIONS */

/* COLOR */

$slide-title-font: normal normal normal 28px $title-font-family;

@mixin slick-controls-default() {
	.slick-controls, .slick-nav {
		button.slick-arrow {
			&.slick-prev {
				background: $slick-default-prev-bg;
				border: $slick-default-border;
				&:hover {
					background: $slick-default-prev-hover-bg;
					border: $slick-default-hover-border;
				}
			}
			&.slick-next {
				background:$slick-default-next-bg;
				border: $slick-default-border;
				&:hover {
					background:$slick-default-next-hover-bg;
					border: $slick-default-hover-border;
				}
			}
		}
		.slick-dots {
			li {
				button {
					border:$slick-default-dot-border;
					&:hover {
						border:$slick-default-dot-border;
						background-color:$slick-default-dot-hover-bg-color;	
					}
				}
				&.slick-active button {
					background-color:$slick-default-dot-active-bg-color;
					border:$slick-default-dot-hover-border;
					&:hover {
						background-color:$slick-default-dot-active-hover-bg-color;						
					}
				}
			}
		}
	}
	
	.slick-controls { background: $slick-default-controls-bg; }
	.slick-nav { background: $slick-default-nav-bg; }
	
	.card-slide-description {
		background-color:$slide-default-text-bg-color;
		color:$slide-default-text-color;
		font:$slide-title-font;
		text-align:center;

		/* @include media($narrow) {
			background-color:$slide-default-text-bg-color;
		} */
	}
}

@mixin slick-controls-highlight() {
	.slick-controls, .slick-nav {
		button.slick-arrow {
			&.slick-prev {
				background:$slick-highlight-prev-bg;
				border:$slick-highlight-border;
				&:hover {
					background:$slick-highlight-prev-hover-bg;
					border:$slick-highlight-hover-border;
				}
			}
			&.slick-next {
				background:$slick-highlight-next-bg;
				border:$slick-highlight-border;
				&:hover {
					background:$slick-highlight-next-hover-bg;
					border:$slick-highlight-hover-border;
				}
			}
		}
		.slick-dots {
			li {
				button {
					border:$slick-highlight-dot-default-border;
					&:hover {
						border:$slick-highlight-dot-hover-border;
						background-color:$slick-highlight-dot-hover-bg-color;	
					}
				}
				&.slick-active button {
					background-color:$slick-highlight-dot-active-bg-color;
					border:$slick-highlight-dot-default-border;
					&:hover {
						background-color:$slick-highlight-dot-active-hover-bg-color;						
					}
				}
			}
		}
	}
	.slick-controls { background: $slide-highlight-text-bg-color; }
	.slick-nav { background: $slide-highlight-text-bg-color; }
	
	.card-slide-description {
		background-color:$slide-highlight-text-bg-color;
		color:$slide-highlight-text-color;
		font:$slide-title-font;
		text-align:center;

		/* @include media($narrow) {
			background-color:$slide-highlight-text-bg-color;
		} */
	}
}



@mixin slick-controls-alt-1() {
	.slick-controls, .slick-nav {
		button.slick-arrow {
			&.slick-prev {
				background:$slick-alt-1-prev-bg;
				border:$slick-alt-1-border;
				&:hover {
					background:$slick-alt-1-prev-hover-bg;
					border:$slick-alt-1-hover-border;
				}
			}
			&.slick-next {
				background:$slick-alt-1-next-bg;
				border:$slick-alt-1-border;
				&:hover {
					background:$slick-alt-1-next-hover-bg;
					border:$slick-alt-1-hover-border;
				}
			}
		}
		.slick-dots {
			li {
				button {
					border:$slick-alt-1-dot-default-border;
					&:hover {
						border:$slick-alt-1-dot-default-border;
						background-color:$slick-alt-1-dot-hover-bg-color;	
					}
				}
				&.slick-active button {
					background-color:$slick-alt-1-dot-active-bg-color;
					border:$slick-alt-1-dot-hover-border;
					&:hover {
						background-color:$slick-alt-1-dot-active-hover-bg-color;						
					}
				}
			}
		}
	}
	.slick-controls { background: $slide-alt-1-text-bg-color; }
	.slick-nav { background: $slide-alt-1-text-bg-color; }
	
	.card-slide-description {
		background-color:$slide-alt-1-text-bg-color;
		color:$slide-alt-1-text-color;
		font:$header-menu-font;
	}
}
