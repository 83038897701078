section .section-row.row-bg-video {
	padding: 0;
	width: 100%;
	position: relative;
	line-height: 0;
	.section-inner {
		max-width: $site-max-width;
		width: 100%;
		padding: 0;
	}
	&:before,
	&:after {
		z-index: 3;
		content: " ";
    position: absolute;
    bottom: -1px;
    width: calc(50% + 5px);
    height: 20%;
	}
	&:before {
    left: 0;
		-webkit-clip-path: polygon(0% 20%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 20%, 100% 100%, 0% 100%);
	}
	&:after {
    right: 0;
		-webkit-clip-path: polygon(100% 20%, 100% 100%, 0% 100%);
    clip-path: polygon(100% 20%, 100% 100%, 0% 100%);
	}
	
	@supports (-ms-ime-align:auto) {
    &:before,
		&:after { display: none; }
}
	
	::-webkit-media-controls,
	::-webkit-slider-thumb,
	::-moz-range-track{
		position: relative;
		z-index: 4;
		top: 0;
	}
	
	
	&.palette-default {
		&:before,
		&:after {
			@include media ($narrow) {
				background: $palette-default-bg-color;
			}
		}
	}
	
	&.palette-alt-1 {
		&:before,
		&:after {
			@include media ($narrow) {
				background: $palette-alt-1-bg-color;
			}
		}
	}
	
	&.palette-alt-2 {
		&:before,
		&:after {
			@include media ($narrow) {
				background: $palette-alt-2-bg-color;
			}
		}
	}
	
	&.palette-alt-3 {
		&:before,
		&:after {
			@include media ($narrow) {
				background: $palette-alt-3-bg-color;
			}
		}
	}
	
	&.palette-highlight {
		&:before,
		&:after {
			@include media ($narrow) {
				background: $palette-highlight-bg-color;
			}
		}
	}		
}

