// /* DEFAULT CARD LAYOUT	*/
.card {
	border:$card-border;
	border-radius: $card-border-radius;
	box-shadow:$card-shadow;
	margin-bottom:$card-margin-bottom;
	
	transition: $card-transition;

	&.slick-slide {
		margin-top: 10px;
	}
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}

	h3 {
		display:block;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
		}
	}	
	.card-text {
		padding: $card-text-padding;
		a { word-wrap: break-word; }
	}
}

.card-display-date {
	background: $card-date-bg-color;
	border-bottom:$card-date-border;
	border-radius:$card-date-border-radius;
	color:$card-date-text-color;
	font:$card-date-font;
	padding:25px 20px 5px;
}


// /* MORE LINK */
.section-row:not(.view-type-slider), .side-row:not(.view-type-slider)
{
	.card-more-link {
		display:table !important;
		text-align:center;

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:16px;
				margin:0 0 -2px 10px;
				width:14px;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

.section-row:not(.view-type-slider) {
	.card-more-link {
		margin-left:0px;

		@media (max-width:$narrow) {
			width:100%;
		}
		
		@include media($narrow) {
				margin-left:2px;
		}
	}
}

.side-row:not(.view-type-slider)
{
	.card-more-link {
		margin-left:0px;
		width:100%;
	}
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	height:45px;
	margin-top: 2px;
	overflow: hidden;
	a {
		display: inline-block;
		padding: $vert-space/2 $horz-space/2;		
		&:after {
			content: "";
			display: inline-block;
			width: 18px;
			height: 20px;
			//border-radius: 50%;
			margin-left: 10px;
			vertical-align: sub;
		}
	}
}



// /* INNER CARD ELEMENTS */
.tweet .card-text {
	display:block;
	font:normal 1.125em/1.22222222222222 $base-font-family;
}

.card-logo {
	/* background:#FFFFFF; */
	padding: $vert-space/2 $horz-space/2 0 $horz-space/2;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.card-tags {
	display: inline-block;
	font-size: 0.875em;
	font-weight: 600;

	ul {
		display: inline-block;
		li {
			float: left;
			margin-right: 10px;
			&:after {
				content:"|";
				display: inline-block;
				float: right;
				margin-left: 10px;
			}
			&:last-child {
				&:after {
					content:"";
				}
			}					
		}
	}
}

.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-citation {
	font-style:italic;
}
.card-date,
.card-location {
	font-size:1em;
	line-height:1.57142857142857;
}
.card-type {
	font-size:0.875rem;
	line-height:1.57142857142857;
}
.card-label {
	font-weight:bold;
}
.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}

.card-date-display-override {
	font-size: 0.875em;
}
.card-resource-type,
.card-content-type {
	display:inline-block;
	font-weight: 400;
	font-size: 0.875em;
}
.card-journal {
	display: inline-block;
	font-weight: 400;
	font-size: 0.875em;
	&:after {
		content:"|";
		margin: 0 0px 0 2px;	
	}
}

.card-footer {
	display: block;
	line-height: 1.125em;
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}
//Display box-link card-summary on hover 
//@include card-hidden-summary;