 main {
	 #sidebar-second {
		background: $sidebar-second-bg;
		margin: $sidebar-second-margin;
		padding: $sidebar-second-padding;
	}

	.side-row {
		background: $side-row-bg;
		border: $side-row-border;
		border-radius: $side-row-border-radius;
		box-shadow: $side-row-box-shadow;
		margin: $side-row-margin;
		padding:$side-row-padding;
		overflow: hidden;
		
		@include palette-sidebar-slick-controls;
		@include palette-sidebar-button-link;
		@include palette-sidebar-faq;
		@include palette-sidebar-attachments;

		 &.section-with-slider {
			 padding:0;
		 }

		.section-inner {
			padding: $side-row-inner-padding;
			@media (max-width:$narrow) {
				padding: $side-row-inner-mobile-padding;
			}		
		}

		h2 {
			font:$side-row-title-font;
			.card-title {
				font-size: inherit;
			}
		}
		
		.card-title h2 {
			margin: -20px -20px 20px -20px;
			padding: 14px 20px 11px;
			background: $pastel_blue;
			border-bottom: 1px solid $light_sea_green;
			font:$side-row-title-font;
		}

		.card {
			@include palette-sidebar-cards;

			border: $card-sidebar-border;
			border-radius: $card-sidebar-border-radius;
			box-shadow: $card-sidebar-shadow ;
			margin-bottom: $card-sidebar-margin-bottom;

			.card-text {
				padding: $card-text-padding;
			}
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {	
			@include palette-sidebar-card-more-link;
		}
		.card-feed-link {
			@include palette-sidebar-card-feed-link;
		}
		
		.view-tagged .views-row .card-title-et {
			@include palette-sidebar-cards;

			border: $card-sidebar-border;
			border-radius: $card-sidebar-border-radius;
			box-shadow: $card-sidebar-shadow ;
			margin-bottom: $card-sidebar-margin-bottom;

			h3 {
				font: $card-title-font;
				margin: 0;
				 a {
					padding: $card-text-padding;
					display: block;
				}
			}
		}
	}
} 