.gnatwork {
	&:not(.role-editor) {
		.field-name-field-gnat-person-er,
		#edit-timezone {
			display: none;
		}
	}
}

.page-members{
	#page-title
	{
		font:$section-row-title-font;
	}
	
	.views-submit-button {
		padding:0px;
		float:right;
		margin-right:$horz-space*2;
		
		@include media ($narrow) {
			margin-right:$horz-space;
		}
	}
	
	.views-reset-button {
		padding:0px;
		float:right;
		margin-right:$horz-space*1.5;
		
		@include media ($narrow) {
			margin-right:$horz-space;
		}
	}
	.views-exposed-form {
		padding: $vert-space*1.5 $horz-space*1.5;
	}
}

.views-exposed-widgets {
	background-color:$platinum;
	//padding:$vert-space 0 $vert-space*1.5 $horz-space*1.5;
	
	.views-widget-filter-combine
	{
		padding-right:0px;
		margin-right:10%;
		
		.form-text
		{
			border:$header-search-field-border;
			background-color:$white;
			color: $dark_liver;
		}
	}
	
	.views-widget-filter-field_gnat_vector_value_selective
	{
		label
		{
			padding-bottom:10px;
		}	
	}
}
	
.bef-checkboxes {
	display:flex;
	flex-wrap: wrap;
	
	.form-type-bef-checkbox
	{
		display:inline-block;
		padding-right:50px;
		
		.option
		{
			font:normal normal normal $base-font-size $base-font-family;
		}

	}
}

.card-gnat-user-photo {
	margin: 10px 10px 0 10px;
	height: 100%;
}
		

.row-max-1 .card-gnat-user-photo {
	max-width: 300px;
	position: relative;
	margin: 10px auto 0;
	text-align: center;
	padding: 0 10px;
	@include media (540px) {
		@include span-columns(4);
		margin: 10px 10px 10px 0;
		padding: 0;
		
		float: right;
		max-width: 200px;
		&:last-child { margin-right: 10px; }
	}
}
		
.member-name {
	margin-bottom:$vert-space/4;

	div { display: inline; }
}

.member-job {
	font-size: 18px;
	margin-bottom: $vert-space;

	div {
		display: inline;
	}
	
	.card-job-title + .card-institution:before { 
		content: ",";
		display: inline;
		margin: 0 2px 0 -4px;
	}
}

.label { 
	font-weight:bold;
	margin-right: $horz-space/2;
}

.attachment {
	margin: $vert-space*1.5 0 $vert-space*2 0;
}

.views-summary { 
	a { 
		padding:8px 15px;
		color:$light-sea-green;
		background-color:$white;
		font: $button-font;
		font-size:22px;
		line-height:50px;
		
		&:hover {
			color:$white;
			background-color:$light-sea-green;
		}
	}
}

.card-gnat-vector {
	margin-bottom: $vert-space/2;
}

.card-mail {
	margin-bottom: $vert-space;
}

.card-gnat-bio {
	font-size:18px;
	margin-bottom: $vert-space;
}

.card-gnat-career-stage {
	background: lighten(#dde5eb,5%);
	display:inline-block;
	color:darken(#dde5eb,50%);
	//font-weight:bold;
	text-transform: uppercase;
	border: 1px solid darken(#dde5eb,12%);
	padding:$vert-space/4 $horz-space/2;
	
	    padding: 3px 8px;
    font-size: 0.875rem;
}

.palette-alt-3 .card-gnat-career-stage {
	color: $primary;
	border-color: $primary;
	background-color: #7AE6DE;
}

.stage-established {
	display:none;
}

.leaflet-real-name {
	display: none;
}
.leaflet-popup-content-wrapper {
	border-radius: $card-border-radius;
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
	box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);
}
.leaflet-popup-content {
	margin: $vert-space $horz-space;
}
.leaflet-text {
	font-family:  $base-font-family;
	padding: 0;
	div {
		font-size: 1rem;
		line-height: 1.5;
	}
	.member-name { font:bold 1.25rem/1.25 $card-font-family; }
	.member-job {
		font-size:1.125rem;
		line-height: 1.444444444444444;		
	}
}
.leaflet-gnat-pointer {
	background: $map-pointer no-repeat center top / contain;
	margin-left: -13px !important;
	margin-top: -40px !important;
	
}
.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
	background: none;
	div {
		background: $map-pointer no-repeat center center / contain;
		height: 36px !important;
		margin-top: -15px !important;

	}	
}
.result-count {
	padding: 30px 0 0;
	font-size: 1.125rem;
}

.view-id-gnat_members.view-display-id-attachment_2 {
    margin-top: -10px;
}