.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: $vert-space/2 0;
		margin: $vert-space/10 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space/14;
		
		.file-icon {
			float:left;
			margin:0 $horz-space 0 $horz-space/2;
			min-width:4rem;
			.file-extension {
				padding:$vert-space*0.75 $horz-space/4;
				text-transform:uppercase;
				font-size:16px;
        		line-height:1;
				text-align:center;
				font-weight:bold;
			}
			
			.file-size {
        		padding: 3px 5px;
				font-size:0.750em;			
				text-align:center;
				color:$secondary;
        		border:$secondary-border;
        		border-top:none;
        		background-color:$white;
        		font-weight:bold;
			}
		}
		
		.file-name {
			overflow:hidden;
			font-weight:bold;
			font-size:22px;
		}
	}


	// /* FILE TYPE COLOURS */
  
  	.file-type-docx, .file-type-doc {
    	.file-extension {
      		background:$docx_color;
      		color:white;
    	}
  	}
  	
  	.file-type-xlsx, .file-type-xls {
    	.file-extension {
      		background:$xlsx_color;
      		color:white;
    	}
  	}
  	.file-type-pptx, .file-type-ppt {
    	.file-extension {
      		background:$pptx_color;
      		color:white;
    	}
  	}
  	.file-type-pdf {
    	.file-extension {
      		background:$pdf_color;
      		color:white;
    	}
  	}
  	
  	.file-type-rtf, .file-type-txt {
    	.file-extension {
      		background:$txt_color;
      		color:white;
    	}
  	}
  	
  	.file-type-jpg, .file-type-png, .file-type-bmp, gif {
    	.file-extension {
      	background:$jpg_color;
      	color:white;
    	}
  	}
  	
  	.file-type-zip, .file-type-rar, .file-type-7z {
    	.file-extension { 
      		background:$zip_color;
      		color:white;
    	}
  	}
} 
