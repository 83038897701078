// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary            	: #393D40; /* black olive */
$secondary          	: #343434; /* jet */
$blue_sapphire      	: #12605A;  /* blue_sapphire */
$bright_navy_blue   	: #1A76B9; /* bright_navy_blue */
$cyan_cornflower_blue 	: #1B8AC6; /* cyan_cornflower_blue */
$dark_liver 			: #494E51; /* dark_liver */
$spanish_gray           : #919D9D; /* spanish_gray */ /* for footer-third */
$taupe_gray				: #7D868C; /* taupe_gray */ /* search */

// /* OLD PALETTE */ //
// /* MAIN PALETTE */ //
$dogwood_rose      : #D81E5B; /* dogwood_rose */
$light_blue        : #84D0F5; /* light_blue */
$faded_blue        : #5D86C5; /* faded_blue */
$middle_red_purple : #38023B; /* middle_red_purple */

// /* SEC PALETTE */ //
$pale_grey         : #f5f8fa; /* pale_grey */
$pale_grey_two     : #e4e8eb; /* pale_grey_two */
$battleship_grey   : #6b7280; /* battleship_grey */
$japanese_indigo   : #2C3F4E; /* japanese_indigo */
/* $platinum          : #e0e0e2; */ /* platinum */
$pearl_aqua        : #81D2C7; /* pearl_aqua */
$dark_lavender     : #6250A1; /*  dark_lavender  */

// /* NEW PALETTE */ //
// /* PRIMARY PALETTE */ //
$light_sea_green    : #20A79D; /* light_sea_green */
$liberty            : #53599A; /* liberty */
$bright_navy_blue   : #1A76B9; /* bright_navy_blue */

// /* SECONDARY PALETTE */ //
$black_olive        : #393D40; /* black_olive */
$blue_sapphire      : #12605A; /* blue_sapphire */
$pastel_blue        : #A3CFCB; /* pastel_blue */
$platinum           : #D0E6E4; /* platinum */

// /* BACKGROUND PALETTE */ //
$anti_flash_white   : #E6F2F1; /* anti_flash_white */       
/* Alt 1 background */
/* $anti_flash_white : #E6F2F1; */ /* anti_flash_white */ /* Alt 2 background */
$cyber_grape        : #414679; /* cyber_grape */

// /* BOX PALETTE */ //
$columbia_blue      : #C0D9EB; /* columbia_blue */ /* Alt 1 */
/* Default, Highlight, Alt 2 */
$maximum_blue_green : #25C4B8; /* maximum_blue_green */ /* Alt 3 */

/* Alt-3 hover */
$middle_blue 				: #7AE6DE; /* middle_blue */ /* Alt 3 */

// /* OTHER PALETTE */ //
$japanese_indigo   	: #2C3F4E; /* japanese_indigo */
$aero   			: #7CBAE6; /* aero */
$ghost_white  		: #F5F9FC; /* aero */

$black             : #000;
$grey              : #666;
$white             : #FFF;


// MENU
$middle_red					: #E28871;
$pastel_pink				: #E2B1A5;
$dust_storm					: #E2C7C0;
$timberwolf					: #E2D8D5;


// FILES
$dark_spring_green      : #207347; /* xlsx */
$bdazzled_blue 		    : #2D5994; /* docx */
$lemon_curry 		    : #D19B15; /* jpg */
$internationl_orange    : #C11E07; /* pdf */
$dark_pastel_red 		: #D04727; /* pptx */
$dark_imperial_blue 	: #023A6D; /* txt */
//$black 					: #000000; /* zip */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue; /* txt */
$zip_color	 					: black; /* zip */


// /* LINKS */ //
$action-default      : $bright_navy_blue;
$action-hover        : $light_sea_green;
$action-active       : $bright_navy_blue; 
$action-active-hover : $light_sea_green; 


// /* SHADOWS */ //
$box-shadow : 0 2px 4px 0 rgba(0,0,0,0);


// /* BORDERS */ //
$primary-border-color          : rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : 1px solid $primary-border-color;

$secondary-border-color        : rgba(44, 63, 78,0.1);
$secondary-border-color-radius : 0;
$secondary-border              : 1px solid $secondary-border-color;

$tertiary-border-color         : #F3F3F3;
$tertiary-border-radius        : 0;
$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
$table-bg-color           : white;
$base-accent-color        : #477DCA;
$table-border-color       : $primary-border-color;
$table-cell-border        : 1px solid $pale_grey;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : $pale_grey;
$table-header-border      : 1px solid darken($primary-border-color, 10%);
$table-hover-bg-color     : lighten($platinum, 10%);
$table-stripe-bg          : darken($table-bg-color, 4);
$table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .75em 1em;
$table-caption-bg-color   : $grey;
$table-caption-text-color : white;
$table-caption-border     : 1px solid white;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

// /* FORMS  */ //
$fieldset-border-color : $secondary;
$form-box-shadow       : $box-shadow;
$form-box-shadow-focus : $box-shadow; 
$form-border-color     : $columbia_blue;
$form-border           : 2px solid $columbia_blue;
$form-border-radius    : 0;

$button-bg             : $liberty;
$button-hover-bg       : $bright_navy_blue;
$button-text           : white;
$button-border         : none;
$button-border-radius  : 0;
$button-hover-text     : white;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $pale_grey !default;