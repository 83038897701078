.page-node-84 {
	.content-main {
		h1 {
			font: $card-title-font; 
		}
		
	}
}

.views-exposed-form {
	//@include span-columns(7);
	background-color: $platinum;
	padding: 1rem;
	
	@media (max-width:$narrow) {
		width: 100%;
		padding-left:0px;
	}
	
	.views-exposed-widget{
		margin-right: 1rem;
	}
	
	.views-widget {
		.form-type-textfield {
			input {
				color: $dark_liver;
				font: $header-search-field-font;
				border: $header-search-field-border;
				width: 200px;
			}
		}
		
		::-ms-expand {
   			display: none;
		}
		
		.form-select {
			-webkit-appearance: none;

			color: $dark_liver;
			font: $header-search-field-font;
			vertical-align: top;
			width: 7rem;
			// padding: 2rem;
			border: $header-search-field-border;
			background:$arrow-iceberg-dropdown no-repeat scroll 90% 50% white;
			background-size: 23%;
		}
	}
	
	.views-submit-button {
		padding:0px;
		float:right;
		margin-right:$horz-space*2;
		
		@include media ($narrow) {
			margin-right:45px;
		}
	}
	
	.views-reset-button {
		padding:0px;
		float:right;
		margin-right:$horz-space*1.5;
		
		@include media ($narrow) {
			margin-right:$horz-space;
		}
	}
	
	label {
		margin-bottom: $vert-space/2;
	}
}