.credit,
.vid-description {
	background: rgba(255, 255, 255, 0.5) none repeat scroll 0 0;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	float: right;
	font-size: 0.75em;
	line-height: 1.125rem;
	margin-bottom: -27px;
	margin: 8px 0;
	padding: 1px 22px 1px 10px;
	position: absolute;
	right: -10px;
	text-align: right;
	top: 0;
	
	&:empty {
		display: none;
	}

	&.collapsed {
		background: rgba(0, 0, 0, 0.5) $ico-info no-repeat 50% 50%;
		height: 22px;
		margin-right: 0;
		text-align: inherit;
		text-indent: -9999px;
		transition: background-color 0.5s linear;
		width: 22px;

		&:hover {
			background-color: rgba(0, 0, 0, 0.75);
		}
	}
}