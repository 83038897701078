// Font styles for the following can be configured in var-typography

// /* HEADER */ //
$header-bg-color                   : transparent;

$header-menu-link-color            : $blue_sapphire;
$header-menu-link-hover            : $white;

$header-search-field-bg-color      : $white;
$header-search-field-text-color    : $taupe_gray;
$header-search-field-box-shadow    : none;
$header-search-field-border        : 2px solid $columbia_blue;
$header-search-field-border-radius : 30px;


// /* MAIN MENU */ //
$nav-wrapper-bg-color			: transparent;
$nav-base-bg-color        		: $white; 
$nav-base-text-color     		: $blue_sapphire;
$nav-base-padding        		: 1.25rem;
$nav-base-hover-padding   		: $nav-base-padding;
$nav-sub-base-padding			: 1rem 1.25rem;
$nav-sub-base-hover-padding		: $nav-sub-base-padding;

$nav-base-padding-smaller 			: $nav-sub-base-padding; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 	: $nav-sub-base-padding;

$nav-active-bg-color      		: $light_sea_green;
$nav-active-text-color    		: $white;
$nav-active-border-radius 		: 0; 

$nav-hover-bg-color       		: $liberty;
$nav-hover-text-color     		: $white;
$nav-hover-border	  	    	: none;

$nav-highlight-bg-color   		: $platinum;
$nav-highlight-text-color 		: $blue_sapphire; 
$nav-highlight-border	        : none;

$nav-sub-bg-color         		: $white;
$nav-sub-hover-bg-color   		: $liberty;
$nav-sub-text-color       		: $primary;
$nav-sub-hover-text-color   	: $white;
$nav-sub-box-shadow      		: $box-shadow;
$nav-sub-border          		: none;
$nav-sub-border-radius  		: none;

$nav-mob-text-color      		: $blue_sapphire;
$nav-mob-active-text-color    	: $white;
$nav-mob-active-bg-color      	: $light_sea_green;
$nav-mob-padding      			: 0.75rem 1.25rem;

$nav-mob-active-trail-text-color : $white;
$nav-mob-active-trail-bg-color   : $light_sea_green;

$nav-mob-1st-bg-color     			: $white;
$nav-mob-1st-highlight-bg-color     : $liberty;
$nav-mob-1st-highlight-text-color   : $white;
$nav-mob-1st-line-color     		: $anti_flash_white;
$nav-mob-1st-border-color     		: $liberty;

$nav-mob-2nd-bg-color     			: $anti_flash_white;
$nav-mob-2nd-highlight-bg-color     : $pastel_blue;
$nav-mob-2nd-highlight-text-color   : $primary;
$nav-mob-2nd-line-color     		: white;
$nav-mob-2nd-border-color     		: $light_sea_green;

$nav-mob-3rd-bg-color     			: $platinum;
$nav-mob-3rd-highlight-bg-color     : $pastel_blue;
$nav-mob-3rd-highlight-text-color   : $primary;
$nav-mob-3rd-line-color     		: white;
$nav-mob-3rd-border-color     		: $light_sea_green;

/* $nav-mob-4th-bg-color     			: $platinum;
$nav-mob-4th-highlight-bg-color     : $liberty;
$nav-mob-4th-highlight-text-color   : $white;
$nav-mob-4th-line-color     		: white;
$nav-mob-4th-border-color     		: none; */

$nav-mob-border						: 1px solid $anti_flash_white;
$nav-mob-expanded-border			: 1px solid $white;
$nav-mob-border-hover				: none;
$nav-mob-shadow						: none;

$nav-arrow-right-size				: 60%;
$nav-arrow-down-size				: $nav-arrow-right-size + 10%;
$nav-sub-arrow-right-size			: 40%;
$nav-sub-arrow-down-size			: $nav-sub-arrow-right-size + 10%;

$nav-button-bg-color				: $light_sea_green;
$nav-button-hover-bg-color			: $light_sea_green;
$nav-button-height      			: 74px;
$nav-button-width      				: 76px;
$nav-button-bar-bg-color      		: $light_sea_green;
$nav-button-bar-hover-bg-color  	: &white;
$nav-button-bar-height      		: 6px;
$nav-button-bar-width      			: 46px;
$nav-button-bar-border-radius 		: 8px;

// /* BREADCRUMBS */ //
$breadcrumb-bg-color     : $platinum;
$breadcrumb-action-color : $cyan_cornflower_blue;
$breadcrumb-hover-color  : $white;
$breadcrumb-active-color : $dark_liver;
$breadcrumb-div-color    : $primary;


// /* PRIMARY TABS */ //
$tab-active-bg-color     : $anti_flash_white;
$tab-active-text-color   : $primary;
$tab-active-border       : $primary-border;
$tab-inactive-bg-color   : $base-background-color;
$tab-inactive-text-color : $action-default;
$tab-hover-bg-color      : $nav-hover-bg-color;
$tab-hover-text-color    : $nav-hover-text-color;
$tab-border              : 1px solid $nav-highlight-bg-color;
$tab-border-bottom       : $primary-border;



// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-shadow                   	: $box-shadow;
$card-border                   	: none;
$card-border-radius            	: 0;
$card-text-padding             	: $vert-space $horz-space !default;
$card-margin-bottom            	: 2px;
$card-transition             	: background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES */
$card-date-bg-color            : transparent;
$card-date-border              : none; //1px solid $pale_grey
$card-date-border-radius       : 0;
$card-date-text-color          : $primary;
$card-date-font                : 1em/1 $base-font-family;


// /* --- HOVER STYLES: SAME FOR ALL CARDS */
$card-hover-bg-color           : $white;
$card-hover-border             : none;
$card-hover-shadow             : $box-shadow;
$card-action-hover             : $bright_navy_blue; //colour of card title text when you hover over it
$card-text-hover-color         : $primary; //colour of regular card text when you hover over it
$card-date-hover-text-color    : $primary /*lighten($grey,6%)*/;
$card-date-hover-bg-color      : transparent /*$pale_grey*/; // allows display dates (e.g. events) to have different hover background from the rest of the card

$card-hover-alt-bg-color       		: $middle_blue;
$card-hover-alt-border         		: none;
$card-hover-alt-shadow         		: $box-shadow;
$card-action-hover-alt              : $primary; //colour of card title text when you hover over it
$card-text-hover-alt-color          : $primary; //colour of regular card text when you hover over it
$card-date-hover-alt-text-color     : $primary /*lighten($grey,6%)*/;
$card-date-hover-alt-bg-color       : transparent /*$pale_grey*/; // allows display dates (e.g. events) to have different hover background from the rest of the card

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- un/comment the appropriate line in base/_cards.scss as appropriate
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : $white;
$card-light-border-color       : transparent;
$card-light-title-color        : $primary;
$card-light-action-default     : $bright_navy_blue;
$card-light-action-hover       : $bright_navy_blue; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color         : $primary;
$card-light-subtitle-color     : $primary;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $columbia_blue;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $primary;
$card-medium-action-default    : $bright_navy_blue;
$card-medium-action-hover      : $bright_navy_blue; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color        : $primary;
$card-medium-subtitle-color    : $primary;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : $maximum_blue_green;
$card-dark-border-color        : transparent;
$card-dark-title-color         : $primary;
$card-dark-action-default      : $primary;
$card-dark-action-hover        : $white; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-dark-text-color          : $primary;
$card-dark-subtitle-color      : $primary;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : $white;
$card-highlight-border-color   : transparent;
$card-highlight-title-color    : $primary;
$card-highlight-action-default : $primary;
$card-highlight-action-hover   : $primary; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-highlight-text-color     : $primary;
$card-highlight-subtitle-color : $primary;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : $white;
$card-more-link-default-color       : $primary;
$card-more-link-default-icon        : $arrow-grey-right no-repeat 100% 100% / 14px auto;
$card-more-link-default-hover-bg    : $white;
$card-more-link-default-hover-color : $bright_navy_blue;
$card-more-link-default-hover-icon  : $arrow-blue-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-bg              : $columbia_blue;
$card-more-link-alt-color           : $primary;
$card-more-link-alt-icon            : $arrow-grey-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-hover-bg        : $columbia_blue;
$card-more-link-alt-hover-color     : $bright_navy_blue;
$card-more-link-alt-hover-icon      : $arrow-blue-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-3-bg              : $maximum_blue_green;
$card-more-link-alt-3-color           : $primary;
$card-more-link-alt-3-icon            : $arrow-grey-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-3-hover-bg        : $middle_blue;
$card-more-link-alt-3-hover-color     : $primary;
$card-more-link-alt-3-hover-icon      : $arrow-grey-right no-repeat 100% 100% / 14px auto;


// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : $liberty; // background for the entire link
$card-feed-link-default-color       : $white;
$card-feed-link-default-icon        : $liberty $arrow-white-right no-repeat scroll 50% 50% / 12px 16px; //background for the default :after component
$card-feed-link-default-hover-bg    : $bright_navy_blue;
$card-feed-link-default-hover-color : $white;
$card-feed-link-default-hover-icon  : $bright_navy_blue $arrow-white-right no-repeat scroll 50% 50% / 12px 16px;;

$card-feed-link-alt-bg              : $white; // background for the entire link
$card-feed-link-alt-color           : $liberty;
$card-feed-link-alt-icon            : $white $arrow-blue-right no-repeat scroll 50% 50% / 12px 16px; //background for the alternative :after component
$card-feed-link-alt-hover-bg        : $white;
$card-feed-link-alt-hover-color     : $light_sea_green;
$card-feed-link-alt-hover-icon      : $white $arrow-white-right no-repeat scroll 50% 50% / 12px 16px;;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : $liberty;
$button-link-default-border-color       : $liberty;
$button-link-default-color              : $white;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $bright_navy_blue;
$button-link-default-hover-border-color : $bright_navy_blue;
$button-link-default-hover-color        : $white;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : $white;
$button-link-alt-border-color           : $white;
$button-link-alt-color                  : $light_sea_green;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : $light_sea_green;
$button-link-alt-hover-border-color     : $light_sea_green;
$button-link-alt-hover-color            : $white;
$button-link-alt-hover-icon             : none;




// /* MAIN PAGE */ //
$main-page-bg         : $anti_flash_white;
$section-main-padding : $vert-space*1.5 0 !default;




// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: $white; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: transparent $arrow-purple-left no-repeat scroll 50% 50%;
$slick-default-prev-hover-bg      				: transparent $arrow-teal-left no-repeat scroll 50% 50%;
$slick-default-next-bg            				: transparent $arrow-purple-right no-repeat scroll 50% 50%;
$slick-default-next-hover-bg      				: transparent $arrow-teal-right no-repeat scroll 50% 50%;
$slick-default-border         				    : 3px solid $liberty;
$slick-default-hover-border    				    : 3px solid $light_sea_green;
$slick-default-dot-border         				: 2px solid $japanese_indigo;
$slick-default-dot-hover-border    				: 2px solid $light_sea_green;
$slick-default-dot-hover-bg-color               : $liberty;
$slick-default-dot-active-bg-color 				: $light_sea_green;
$slick-default-dot-active-hover-bg-color 		: $light_sea_green;
$slide-default-text-bg-color					: $white;
$slide-default-text-color						: $japanese_indigo;

$slick-highlight-nav-bg              			: $white; // used on slide and galler views
$slick-highlight-controls-bg         			: none; // used on twitter views etc
$slick-highlight-prev-bg             			: transparent $arrow-white-left no-repeat scroll 50% 50%;
$slick-highlight-prev-hover-bg      			: transparent $arrow-teal-left no-repeat scroll 50% 50%;
$slick-highlight-next-bg            			: transparent $arrow-white-right no-repeat scroll 50% 50%;
$slick-highlight-next-hover-bg      			: transparent $arrow-teal-right no-repeat scroll 50% 50%;
$slick-highlight-border         				: 3px solid $white;
$slick-highlight-hover-border    				: 3px solid $light_sea_green;
$slick-highlight-dot-default-border         	: 2px solid $white;
$slick-highlight-dot-hover-border    			: 2px solid $light_sea_green;
$slick-highlight-dot-hover-bg-color             : $light_sea_green;
$slick-highlight-dot-active-bg-color 			: $white;
$slick-highlight-dot-active-hover-bg-color 		: $white;
$slide-highlight-text-bg-color					: $cyber_grape;
$slide-highlight-text-color						: $white;

$slick-alt-1-nav-bg              				: $white; // used on slide and galler views
$slick-alt-1-controls-bg         				: none; // used on twitter views etc
$slick-alt-1-prev-bg             				: transparent $arrow-purple-left no-repeat scroll 50% 50%;
$slick-alt-1-prev-hover-bg      				: transparent $arrow-teal-left no-repeat scroll 50% 50%;
$slick-alt-1-next-bg            				: transparent $arrow-purple-right no-repeat scroll 50% 50%;
$slick-alt-1-next-hover-bg      				: transparent $arrow-teal-right no-repeat scroll 50% 50%;
$slick-alt-1-border         				    : 3px solid $liberty;
$slick-alt-1-hover-border    				    : 3px solid $light_sea_green;
$slick-alt-1-dot-default-border         		: 2px solid $japanese_indigo;
$slick-alt-1-dot-hover-border    				: 2px solid $light_sea_green;
$slick-alt-1-dot-hover-bg-color               	: $liberty;
$slick-alt-1-dot-active-bg-color 				: $light_sea_green;
$slick-alt-1-dot-active-hover-bg-color 			: $light_sea_green;
$slide-alt-1-text-bg-color						: transparent;
$slide-alt-1-text-color							: $primary;

$slick-alt-2-nav-bg                 			: $pale_grey; // used on slide and galler views
$slick-alt-2-controls-bg         				: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: $faded_blue $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-prev-hover-bg         				: $dark_lavender $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-next-bg               				: $faded_blue $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-next-hover-bg         				: $dark_lavender $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-dot-default-border    				: 2px solid $faded_blue;
$slick-alt-2-dot-hover-border      				: 2px solid $faded_blue;
$slick-alt-2-dot-active-bg-color   				: $faded_blue;
$slick-alt-2-dot-active-hover-bg-color 			: $faded_blue;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : rgba(255, 255, 255, 1) ;
$slide-text-color          : $primary;
$slide-text-font           : 1.375rem/1.45 $title-font-family;



// /* SIDEBAR Container styles */

$sidebar-second-bg       : none;
$sidebar-second-border   : none;
$sidebar-second-margin   : 0;
$sidebar-second-padding  : 0;

$side-row-bg                   	: $platinum;
$side-row-border               	: 1px solid transparent transparent blue transparent;
$side-row-border-radius        	: 0px;
$side-row-box-shadow 			: none;
$side-row-padding              	: $vert-space $horz-space;
$side-row-inner-mobile-padding 	: 0;
$side-row-inner-padding        	: 0;
$side-row-margin               	: 0 0 $vert-space*1.25;



/* SIDEBAR CARDS */
@mixin palette-sidebar-cards {
	@include card-light();
}
@mixin palette-sidebar-card-more-link {
	@include card-more-link-default();
}
@mixin palette-sidebar-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-sidebar-button-link {
	@include button-link-default();
}
@mixin palette-sidebar-slick-controls {
	@include slick-controls-alt-1();
}
@mixin palette-sidebar-faq {
	@include faq-default();
}
@mixin palette-sidebar-attachments {
	@include attachments-default();
}

$card-sidebar-border        : none;
$card-sidebar-border-radius : 0;
$card-sidebar-shadow        : $box-shadow;
$card-sidebar-text-padding  : $vert-space $horz-space !default;
$card-sidebar-margin-bottom : 2px;



// /* SECTION/NUGGET ROWS */ /
$section-row-padding          : $vert-space*1.5 0;
$section-inner-mobile-padding : 0 $horz-space/2;
$section-inner-padding        : 0 $horz-space;

// /*Default sets the card colours for main and additional also */
$palette-default-bg-color      : $anti_flash_white;
$palette-default-title-color   : $primary;
$palette-default-text-color    : $secondary;
$palette-default-link-color	   : $action-default;
$palette-default-link-hover    : $action-hover;

//
// --- Set the card style for the DEFAULT band by replacing the card-[mixin] in the mixin below
//
@mixin palette-default-cards {
	@include card-light();
}
@mixin palette-default-card-more-link {
	@include card-more-link-default();
}
@mixin palette-default-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-default-button-link {
	@include button-link-default();
}
@mixin palette-default-slick-controls {
	@include slick-controls-default();
}
@mixin palette-default-faq {
	@include faq-default();
}
@mixin palette-default-attachments {
	@include attachments-default();
}

$palette-highlight-bg-color    	: $cyber_grape;
$palette-highlight-title-color 	: $white;
$palette-highlight-text-color  	: $white;
$palette-highlight-link-color	: $middle_blue;
$palette-highlight-link-hover  	: $action-hover;

//
// --- Set the card style for the HIGHLIGHT band by replacing the card-[mixin] in the mixin below
//
@mixin palette-highlight-cards {
	@include card-light();
}
@mixin palette-highlight-card-more-link {
	@include card-more-link-default();
}
@mixin palette-highlight-card-feed-link {
	@include card-feed-link-alt();
}
@mixin palette-highlight-button-link {
	@include button-link-alt();
}
@mixin palette-highlight-slick-controls {
	@include slick-controls-highlight();
}
@mixin palette-highlight-faq {
	@include faq-highlight();
}
@mixin palette-highlight-attachments {
	@include attachments-default();
}

$palette-alt-1-bg-color        : $white;
$palette-alt-1-title-color     : $primary;
$palette-alt-1-text-color      : $secondary;
$palette-alt-1-link-color      : $action-default;
$palette-alt-1-link-hover      : $action-hover;

//
// --- Set the card style for the ALT-1 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-1-cards {
	@include card-medium();
}
@mixin palette-alt-1-card-more-link {
	@include card-more-link-alt();
}
@mixin palette-alt-1-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-1-button-link {
	@include button-link-default();
}
@mixin palette-alt-1-slick-controls {
	@include slick-controls-default();
}
@mixin palette-alt-1-faq {
	@include faq-default();
}
@mixin palette-alt-1-attachments {
	@include attachments-alt-1();
}

$palette-alt-2-bg-color        : $anti_flash_white $hex-pattern-dark  repeat 0 0 / 61px 50px;
$palette-alt-2-title-color     : $primary;
$palette-alt-2-text-color      : $secondary;
$palette-alt-2-link-color	   : $action-default;
$palette-alt-2-link-hover      : $action-hover;

//
// --- Set the card style for the ALT-2 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-2-cards {
	@include card-light();
}
@mixin palette-alt-2-card-more-link {
	@include card-more-link-default();
}
@mixin palette-alt-2-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-2-button-link {
	@include button-link-default();
}
@mixin palette-alt-2-slick-controls {
	@include slick-controls-default();
}
@mixin palette-alt-2-faq {
	@include faq-default();
}
@mixin palette-alt-2-attachments {
	@include attachments-default();
}

$palette-alt-3-bg-color        : $white;
$palette-alt-3-title-color     : $primary;
$palette-alt-3-text-color      : $secondary;
$palette-alt-3-link-color	   	 : $action-default;
$palette-alt-3-link-hover      : $action-hover;

//
// --- Set the card style for the ALT-3 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-3-cards {
	@include card-dark();
}
@mixin palette-alt-3-card-more-link {
	@include card-more-link-alt-3();
}
@mixin palette-alt-3-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-3-button-link {
	@include button-link-default();
}
@mixin palette-alt-3-slick-controls {
	@include slick-controls-default();
}
@mixin palette-alt-3-faq {
	@include faq-default();
}
@mixin palette-alt-3-attachments {
	@include attachments-alt-3();
}


// /* SEARCH */ //
$search-card-bg-color : white;


// /* HIGHCHARTS */ //
$highcharts-font-family      : 'Georgia', serif !default;
$highcharts-container-margin : $vert-space*1.5 0 !default;


// /* FOOTER */ //
$footer-first-bg           : $primary;
$footer-first-text         : $white;
$footer-first-action       : $maximum_blue_green;
$footer-first-action-hover : $white;
$footer-first-border       : none;

//
// --- Set the card style for FOOTER-FIRST by replacing the card-[mixin] in the mixin below
//
@mixin palette-footer-first-cards {
	@include card-light();
}
@mixin palette-footer-first-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-first-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-first-slick-controls {
	@include slick-controls-default();
}


$footer-second-bg           : $black;
$footer-second-text         : $white;
$footer-second-action       : $white;
$footer-second-action-hover : $pale_grey;

//
// --- Set the card style for FOOTER-SECOND by replacing the card-[mixin] in the mixin below
//
@mixin palette-footer-second-cards {
	@include card-light();
}
@mixin palette-footer-second-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-second-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-second-slick-controls {
	@include slick-controls-default();
}

// /* FOOTER CARD-LINKS  -- Follow . social media icons */
$card-links-action-bg            : transparent;
$card-links-border-radius        : 0;
$card-links-width                : 40px;
$card-links-height               : 40px;
$card-links-hover-opacity        : 1.0;
$card-links-hover-border-radius  : 0;
$card-links-hover-bg             : $light_sea_green;


$footer-third-bg            : $white;
$footer-third-text          : $spanish_gray;
$footer-third-action        : $secondary;
$footer-third-action-hover  : $maximum_blue_green;

/* OTHER */
$background-image-size: 61px 50px;
$image-padding: $vert-space/2 $horz-space/2 0px $horz-space/2;