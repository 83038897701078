.section-row.section-with-gallery {
	.card-photos {
		.slick-slider {
			background-color:$primary;
			overflow:visible;
			.slick-slide {
				margin:0 10px;
				opacity:0.25;
				//transition:opacity 0.25s ease-in-out;
				&.slick-current {
					opacity:1;
				}
			}
		}
	}
}

.pswp__caption__center {
	max-width:45rem;
	text-align:center;
}
.pswp__button--share { display:none; }

.card-photo {
	padding: $image-padding;
}