#site-footer {
	.outer-wrapper {
		padding:0px $horz-space;
	}

	h2 {
		color:$footer-first-text;
	}
	
	ul:not(.contextual-links) {
		padding: 0;

		li {
			list-style: none;
			line-height: 1.5em;
		}
	}
	
	.card-follow-us-on ul li { display: inline-block; }

	.footer-first {
		-moz-appearance: none;
		background: $footer-first-bg  $hex-pattern-light repeat  0 0 / $background-image-size;
		color: $footer-first-text;
		padding:$vert-space 0;
		@include palette-footer-first-slick-controls;
		
		.outer-wrapper {
			border-bottom:$footer-first-border;
			padding-bottom:$vert-space/2;
		}
		
		.card {
			@include palette-footer-first-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-first-card-more-link;
		}		
		.card-feed-link {
			@include palette-footer-first-card-feed-link;
		}	
		
		@media (max-width:880px) {
			padding:0px;
			
			.outer-wrapper
			{
				padding-bottom:0px;
			}
			
			.card-body, .card-follow-us-on {
				padding-left:0px;
			}
		}
		
		a {
			color:$footer-first-action;
			&:hover {
				color:$footer-first-action-hover;
			}
		}
	}
	
	.footer-second {
		background: $footer-second-bg;
		color: $footer-second-text;
		padding:$vert-space 0;
		@include palette-footer-second-slick-controls;
		
		a {
			color:$footer-second-action;
			&:hover {
				color:$footer-second-action-hover;
			}
		}
		.card {
			@include palette-footer-second-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-second-card-more-link;
		}		
		.card-feed-link {
			@include palette-footer-second-card-feed-link;
		}		
		
		section.block {
			margin-bottom:$vert-space;
			@media screen and (min-width: 45em) {
				margin-bottom:0;				
			}
			&:last-child {
				margin-bottom:0;
			}
		}
		.footer-block {
			margin-bottom:$vert-space*2;
			
			@media screen and (min-width: 45em) {
				margin-bottom:0;				
			}
		}
		
	}
	.footer-third {
		background: $footer-third-bg;
		color: $footer-third-text;
		padding:$vert-space/2 0;
		text-align:center;
		font-size:1em;
		
		a {
			color:$secondary;
			&:hover {
				color:$footer-third-action-hover;
			}
		}
		
		#manta-ray-media {
			float: none;
			color: $secondary;
			@include media(880px) {
				float: right;
			}
			
			@media (max-width:880px) {
				padding: $vert-space*0.8 0px;
			}
			
			a {
				&:hover {
					color: $action-hover;
				}
			}
		}
		.block-menu {
			float: none;
			padding: none;
			
			@include media(880px) {
				float: left;
			}
			
			@media (max-width:880px) {
				padding-top: $vert-space*0.8;
			}
			
			ul {
				margin-top: 0;
				margin-bottom: 0;
				li {
					display: inline-block;
					margin-right: 5px;
					padding-right: 10px;
					border-right: 1px solid $secondary-border-color;
				}
				li.last {
					border-right: none;
					margin-right: 0;
					padding-right: 0;
				}
			}
		}
	}

	.card-links {
		display: inline-block;
		margin-bottom: 10px;
		margin-right: 10px;
		text-indent: -9999px;
		border-style: solid;
		border-color: $light_sea_green;
		background-color: transparent;

		a {
			background-color: $card-links-action-bg;
			border-radius: $card-links-border-radius;
			width: $card-links-width;
			height: $card-links-height;
			display:block;
			margin:0;
			
			&:hover {
				opacity: $card-links-hover-opacity;
				border-radius: $card-links-hover-border-radius;
				background-color: $card-links-hover-bg;
			}
		}
		
		/* newsletter */
		.newsletter a,
		a[href^="http://eepurl.com"] {
			background: $ico-newsletter no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;;
			&:hover {
				background: $ico-newsletter-over no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}

		/* linkedin */
		.linkedin a,
		a[href^="https://www.linkedin.com"],
		a[href^="http://www.linkedin.com"],
		a[href^="https://linkedin.com"],
		a[href^="http://linkedin.com"] {
			background: $ico-linkedin-green no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $ico-linkedin-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}
		
		/* twitter */
		.twitter a,
		a[href^="https://www.twitter.com"],
		a[href^="http://www.twitter.com"],
		a[href^="https://twitter.com"], 
		a[href^="http://twitter.com"] {
			background: $ico-twitter-green no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $ico-twitter-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}

		/* facebook */
		.facebook a,
		a[href^="https://www.facebook.com"],
		a[href^="http://www.facebook.com"],
		a[href^="https://facebook.com"],
		a[href^="http://facebook.com"] {
			background: $ico-facebook-green no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $ico-facebook-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}

		/* google+ */
		.g-plus a,
		a[href^="https://www.plus.google.com"],
		a[href^="http://www.plus.google.com"],
		a[href^="https://plus.google.com"],
		a[href^="http://plus.google.com"] {
			background:$ico-gplus-color no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $ico-gplus-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}

		/* instagram */
		.instagram a,
		a[href^="https://www.instagram.com"],
		a[href^="http://www.instagram.com"],
		a[href^="https://instagram.com"],
		a[href^="http://instagram.com"] {
			background:$ico-instagram-green no-repeat scroll 50% 50% / 19px 18px $card-links-action-bg;
			&:hover {
				background: $ico-instagram-white no-repeat scroll 50% 50% / 19px 18px $card-links-hover-bg;
			}
		}

	}
	.menu {
		padding-bottom: 0;
	}

	.copyright {
		padding-left: 1em;
	}

	.utility-menu {
		ul {
			text-align: right;
			font-weight: 600;

			li {
				display: inline;
				padding-right: 1em;

				a {
					color: $footer-first-action;
				}
			}
		}
	}
}

#mc_embed_signup_scroll {
	display:inline;
	.email {
		display:inline-block;
		width:40%;
		background:white;
		vertical-align:top;
		padding:6px 20px;
		min-width:200px;
	}
	.clear {
		display:inline-block;
		width:auto;
		vertical-align:top;
	
		.button {
			padding:6px $horz-space/2;
			background-color: transparent;
			color: $light-sea-green;
			border: 2px solid $light-sea-green;
			
			&:hover {
				background-color: $light-sea-green;
				color: $white;
			}
		}
	}
}

footer #mc_embed_signup_scroll {
	/* email address placeholder */
	::-webkit-input-placeholder {
    	color:$white;
    	opacity:1;
	}

	::-moz-placeholder {
    	color:$white;
    	opacity:1;
	}

	::-ms-placeholder {
    	color:$white;
    	opacity:1;
	}
	
	::-o-placeholder {
    	color:$white;
    	opacity:1;
	}

	::placeholder {
    	color:$white;
    	opacity:1;
	}
	/**/
	
	.email {
		background:transparent;
		border: 1px solid #FFFFFF;
		color:$white;
		padding: 7px 20px;
	}
}