@import url(https://use.typekit.net/hcz0hpm.css);

$base-font-family      			: 'open-sans', sans-serif;
$title-font-family    			: 'jaf-facitweb', serif;
$subtitle-font-family 	 		: 'jaf-facitweb', serif;


$base-font-size					: 16px;
$base-line-height				: 1.5;
	
$base-font-size-normal			: 18px;

$header-menu-font 				: 1rem /*0.778rem/1*/ $base-font-family;
$header-search-field-font 		: 0.875rem/1.286 $base-font-family;
	
$nav-base-font        	 		: bold 1.25rem/1.3 $title-font-family;
$nav-base-font-smaller 			: bold 1rem/1.375 $title-font-family;
$nav-sub-base-font        		: normal 1rem/1.3 $base-font-family;

$breadcrumb-font 				: /*1rem*/ 0.889rem/1 $title-font-family;

$section-row-title-font 		:  bold /*1.5rem/1.25*/ 2rem/1.25 $title-font-family; // /* Nugget title */
$section-row-title-font-smaller : 500 2rem/1.25 $title-font-family; // /* Nugget title */ 

$side-row-title-font 			: bold 1.25rem/1.3 $title-font-family; // /* Sidebar nugget title */

$card-title-font-family			: $base-font-family;
$card-title-font        		: bold /*1.125rem/1.22222222222222*/ /*1.5rem/1.25*/ 1.222rem $card-title-font-family;
$card-font-family       		: $base-font-family;
$card-more-link-font				: bold 1.125rem/1 $base-font-family;
$card-feed-link-font				: bold 1rem/1.375 $base-font-family;

$button-link-font 				: 600 $base-font-size/1 $base-font-family;

/* FORMS */
$button-font 					: 600 $base-font-size/1 $title-font-family;