button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $button-bg;
  border: $button-border;
  border-radius: $button-border-radius;
  border: none;
  color: $button-text;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: $button-hover-bg;
    color:  $button-hover-text;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

a.button,
.button a {
	@include button-link-default();
  border-radius: $button-border-radius;
  border: none;
  cursor: pointer;
  display: inline-block;
  font: $button-link-font;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.views-reset-button {
	.form-submit
	{
		padding:$vert-space/2 $horz-space/2;
		
		background-color: transparent;
		color: $liberty;
		border: 2px solid $liberty;
		
		&:hover
		{
			background-color: transparent;
			color: $liberty;
			border: 2px solid $liberty;
		}
	}
}

.views-exposed-form {
	.views-submit-button,
	.views-reset-button {
		clear:left;
	}
}