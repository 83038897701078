fieldset {
	border: none;
	margin: 0 0 $vert-space;
	padding: $vert-space 0px;
	legend {
		display: block;
		font: $side-row-title-font;
	}
}


input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

label {
	font-weight: 600;
	margin-bottom: $vert-space/4;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
	background-color: $white;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	max-width: 600px;
	color: $black;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

textarea {
	resize: vertical;
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}

input[type="file"] {
	padding-bottom: $vert-space/2;
	width: 100%;
}

select {
	background-color: $white;
	width: auto;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	max-width: 100%;
	padding: $vert-space/3 2*$vert-space $vert-space/3 $horz-space/2;
	transition: border-color;

				-webkit-appearance: none;

			color: $dark_liver;
			font: $header-search-field-font;
			//vertical-align: top;
			//border: $header-search-field-border;
			background: white $arrow-iceberg-dropdown no-repeat scroll 100% 50% /43px 36px;

}

.marker, .form-required {
	color: #39B54A;
}



.page-user {
	.form-item {
		margin: $vert-space 0px;
		width: 100%;
		max-width: 600px;
	}
	select,input[type="text"],input[type="password"] {
		width: 100%;
	}
	fieldset {
		margin-bottom: 40px;
		padding: 20px;
		&:last-child { margin-bottom: 20px; }
		
		legend {
			margin: 0 0px -20px -20px;
			border-bottom: 1px solid $light_sea_green;
			width: 100%;
			max-width: 640px;
		}
		
		.fieldset-legend {
			margin: 20px 0px 0 20px;
			padding: 14px 20px 11px;
			background: $pastel_blue;

			display: inline-block;
		}
				
		fieldset {
			margin-bottom: 20px;
			padding: 0;
			
			legend {
				font-weight: normal;
				margin: 0;
				border: none;
				
				.fieldset-legend {
					margin: 20px 0px 10px;
					padding: 0;
					background: none;
					display: block;
				}
			}
		}
	}
}

#poll-results-wrapper {
	display: none;
}