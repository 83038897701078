// /* GENERAL / RESET STYLES */

//.front .page > main { display:none; }

.section-row.section-with-slider { 
	padding:0; 		
	margin-top: 10px;
}

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:$site-max-width;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:1px; margin-right:1px; }

.slick-slider { margin-bottom: 8px; }

.slick-dotted.slick-slider { margin-bottom: 10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	button.slick-arrow {
		bottom:17px;
		z-index:1000;
		text-indent:-999em;
		font-family:$base-font-family;
		padding: 20px;
		&:before {
			content:"";
		}
		&.slick-prev {
			left:0; //left:1.25rem;
			background-size:contain;
			width:66px;
			height:56px;
//			@include media($narrow) {
//				left:0;
//			}
		}
		&.slick-next {
			right:0;// 1.25rem;
			background-size:contain;
			width:66px;
			height:56px;
//			@include media($narrow) {
//				right:0;
//			}
		}
	}
	.slick-dots {
		//padding:0 20px;
		width:  calc(100% - 132px);
		position: relative;
		margin: 0 auto;
		vertical-align: middle;
		padding-top: 25px;
		li {
			width:5px;
			height:5px;
			margin:5px 10px;
			button {
				display:inline-block;
				width:0.1px;
				height:0.1px;
				border-radius:50%;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}	
}

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead */
.slick-controls {
	min-height:56px;
	position:relative;
	
	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:17px;
		padding: 15px;
	}
	
	.slick-dots {
		bottom:17px;
	}	
}

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:50%;
		}
		@include media($normal) {
			width:33.333%;
		}
	}
}
.one-sidebar {
	.content-main,
	.content-additional {
		.section-row {
			.slick-controls {
				@include media($narrow) {
					width:100%;
				}
				@include media($normal) {
					width:50%;
				}
			}
		}
	}
}

.side-row {
	.slick-controls {
		width: 100%;
		
		.slick-dots {
			@include media($narrow) {
				display: none !important;
			}
			@include media($wide) {
				display: block !important;
			}
		}
	}
					
	.slick-nav {
		height: 50px;
		background-color: transparent !important;
		padding-bottom:20px;
		position:absolute;
		top: -65px;
		left: 3%;
		width: 94%;
	}
	
	.view-twitter .slick-controls .slick-dots {
		@include media($narrow) {
			display: none !important;
		}
		@include media(1450px) {
			display: block !important;
		}
	}
}



// /* SLICK NAV ONLY */
.slick-nav {
	height: 50px;
	background-color: $white !important;
	padding-bottom:20px;
	position:absolute;
	top: -70px;
	left: 0px;
	width:100%;
	
	button.slick-arrow {
		padding: 25px;
	
		&.slick-prev {
			background: $slick-default-prev-bg !important;
			border: $slick-default-border !important;
			&:hover {
				background: $slick-default-prev-hover-bg !important;
				border: $slick-default-hover-border !important;
			}
		}
		&.slick-next {
			background:$slick-default-next-bg !important;
			border: $slick-default-border !important;
			&:hover {
				background:$slick-default-next-hover-bg !important;
				border: $slick-default-hover-border !important;
			}
		}
	}
	
	.slick-dots {
		bottom:14px;
	
		li {
			button {
				border:$slick-default-dot-border !important;
				&:hover {
					border:$slick-default-dot-border !important;
					background-color:$slick-default-dot-hover-bg-color !important;	
				}
			}
			&.slick-active button {
				background-color:$slick-default-dot-active-bg-color !important;
				border:$slick-default-dot-hover-border !important;
				&:hover {
					background-color:$slick-default-dot-active-hover-bg-color !important;						
				}
			}
		}
	}
	
	&:empty { display: none; }
	
	@include media($narrow) {
		width: 233%;
		top: 0px;
	}
	
	@include media($normal) {
		left:40px;
		top:-35px;
		width:380px;
	}					

	@include media($wide) {
		left:80px;
		top:-45px;
		width:380px;
	}
}


// /* SLIDER VIEWS */
.view-slider {
	position:relative;
	.view-content {
		//margin:0;
		.slick-list {
			overflow:visible;
			.slick-track {
				background-color: transparent;
				
				@include media($narrow) {
					background-color: transparent;
				}
				
				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}
						}
					}
					
					.card-slide-description {
						position:relative;
						background-color:$slide-text-bg-color;
						font:$slide-text-font;
						padding:40px 10px 70px 10px;
						margin:0px;
						color:$slide-text-color;
						height:220px;
						left:0px;
						top:0px;
						width:100%;	
					
						p {
							font:$slide-text-font;
							margin: 0;
						}

						@include media($narrow) {
							position:relative;
							width:100%;	
						}
						
						@include media($normal) {
							position:absolute;
							left:40px;
							top:70px;
							height:220px;
							width:400px;
							box-shadow: 3px 3px 5px 0px black;
							font-size: 28px;
						
							p {
								font-size: 28px;
							}
						}					

						@include media($wide) {
							position:absolute;
							left:80px;
							top:180px;
							height:220px;
							width:400px;
							box-shadow: 3px 3px 5px 0px black;
							font-size: 28px;
						
							p {
								font-size: 28px;
							}
						}
						
						/* .card-slide-description {
							padding: 0;
							min-height: auto;
						} */
					}
				}
			}
		}
		
		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}
	
	// /* SLIDER NAVIGATION */
	.view-footer {
		position:absolute;
		bottom:0;
		@include media($narrow) {
			bottom:67px;
		}
		width:100%;
		left:0;
		@include media($narrow) {
			width:300px;
			left:10px;
		}
	}
}

.section-row .view-slider .view-content .slick-list .slick-track .views-row {
	@include media($narrow) {
		margin-left: 1px;
		margin-right: 1px;
	}
}


// /* SLIDERS HINDERED BY SIDEBARS - image */
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image img {
					left: auto;
					width: 100%;
					max-width: 100%;
				}
				.card-slide-description {
					top:0px;
					left:0px;
					position: relative;
					width:100%;
					background:red !important;
					padding: 20px 20px 70px 20px;
					box-shadow:none;
					background-color:transparent !important;
					font: normal normal normal 18px $title-font-family;
				}
			}
			.view-footer {
				bottom: 0;
				position:relative;
				width:100%;
				left: auto;
				
			}
		}
	}
}


// /* PHOTO GALLERY */
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			//margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						/* background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:$white; */
						line-height:1.225;
					}
				}
			}
		}
	}
	
	// /* GALLERY CONTROLS */
	.view-footer {
		text-align:center;
	}
}


// /* SLIDER / TWITTER */
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		//margin:0;
		position: relative;
		.slick-slider {
			//margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						line-height:1.225;
					}
				}
			}
		}
		
	}
	.view-footer {
		text-align:center;
	}
}

.card-slide-link {
	.card-image {
		padding: 0px;
	}
}
