/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;

	.card-qa-answer {
		display: none;
		padding: 10px;	
		box-sizing: border-box;
 
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-qa-question {
		text-decoration: none;
		font-weight: bold;
		padding: 10px 0 10px 30px;
		line-height: 20px;
		display: block;
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		font-size: 22px;

		/* &.active {
			font-weight: bold;
		} */
	}
	
	/* .ico-collapse, .ico-expand {
		width: 20px;
		height: 20px;
		float:left;
		margin-right: 20px;
	}

	.ico-collapse {
		
	}
	.ico-expand {
		
	} */
}