/* FAQ COLOUR OPTIONS */

$faq-default-color: $action-default;
$faq-default-color-hover: $action-hover;
$faq-default-border: none;
$faq-default-bg: $ico-expand-blue no-repeat scroll 0 50% / 16px 16px transparent;
$faq-default-active-bg: $ico-collapse-blue no-repeat scroll 0 50% / 16px 16px transparent;
$faq-default-bg-hover: $ico-expand-teal no-repeat scroll 0 50% / 16px 16px transparent;
$faq-default-active-bg-hover: $ico-collapse-teal no-repeat scroll 0 50% / 16px 16px transparent;

@mixin faq-default() {
	.view-faqs {
		.card-qa-question {
			color: $faq-default-color;
			border: $faq-default-border;
			background:$faq-default-bg;
	
			&.active {
				background: $faq-default-active-bg;
			}
			
			&:hover {
				color: $faq-default-color-hover;
				background:$faq-default-bg-hover;
	
				&.active {
					background: $faq-default-active-bg-hover;
				}
			}
		}
	}
}

$faq-highlight-color: $middle_blue;
$faq-highlight-color-hover: $action-hover;
$faq-highlight-border: none;
$faq-highlight-bg: $ico-expand-middle-blue no-repeat scroll 0 50% / 16px 16px transparent;
$faq-highlight-active-bg: $ico-collapse-middle-blue no-repeat scroll 0 50% / 16px 16px transparent;
$faq-highlight-bg-hover: $ico-expand-teal no-repeat scroll 0 50% / 16px 16px transparent;
$faq-highlight-active-bg-hover: $ico-collapse-teal no-repeat scroll 0 50% / 16px 16px transparent;

@mixin faq-highlight() {
	.view-faqs {
		.card-qa-question {
			color: $faq-highlight-color;
			border: $faq-highlight-border;
			background:$faq-highlight-bg;
	
			&.active {
				background: $faq-highlight-active-bg;
			}
			
			&:hover {
				color: $faq-highlight-color-hover;
				background:$faq-highlight-bg-hover;
	
				&.active {
					background: $faq-highlight-active-bg-hover;
				}
			}
		}
	}
}
