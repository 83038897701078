$section-row-padding          : $vert-space*1.5 0 !default;
$section-inner-mobile-padding : 0 $horz-space/2 !default;
$section-inner-padding        : 0 $horz-space !default;
$cards-separation       			: 2px;



.section-row {
	padding:$section-row-padding;
	
	&.section-with-slider {
		padding:0;
	}
	
	.section-inner {
		overflow: hidden;
		clear: both;
	}
}

.content-main .section-row {
	//padding:$section-row-content-padding;
}

.postscript-first .section-row .section-inner {
	@include outer-container($site-max-width);
	padding:$section-inner-mobile-padding;
	@media (min-width:$narrow) {
		padding:$section-inner-padding;
	}
}

.section-row:not(.view-type-slider) {
	
	&.row-max-1 {
		.card-logo,
		.card-image,
		.card-photo {
			margin-bottom: 10px;
		}
	}

	@media (min-width:540px) and (max-width:719px) {
	
		&.row-max-1, &.row-max-2, &.row-max-3, &.row-max-4, &.row-max-6, &.row-max-9 {
			 .views-row {
				 margin-right:0;
				 
				 .card {
						margin-bottom:$vert-space;
						margin-right:0;
					}
			 }
		}
		
		&.row-max-12 .views-row {
			@include span-columns(1);
			&:nth-child(12n+1) { clear:left; }
			&:nth-child(12n) { margin-right:0; }
		}
		
		&.row-max-6 .views-row,
    &.row-max-4 .views-row,
    &.row-max-3 .views-row,
    &.row-max-2 .views-row {
      @include span-columns(6);			

			width: calc(50% - #{$cards-separation});
			margin-right: $cards-separation;

			&:nth-child(2n) { margin-right:0; }
			&:nth-child(2n+1) { clear:left; }
		}
		

			
			
		&.row-max-12 .card-last-link-url {
			@include span-columns(1);
		}
    
		&.row-max-6 .card-last-link-url,
		&.row-max-4 .card-last-link-url
		&.row-max-3 .card-last-link-url,
		&.row-max-2 .card-last-link-url,
    &.row-max-6 .card-more-link,
		&.row-max-4 .card-more-link,
		&.row-max-3 .card-more-link,
		&.row-max-2 .card-more-link {
			@include span-columns(6);

			width: calc(50% - #{$cards-separation});
			margin-right: $cards-separation;

			&:nth-child(2n) { margin-right:0; }
			&:nth-child(2n+1) { clear:left; }
	 	}
	}

	@include media($narrow) {  
	
		&.row-max-1, &.row-max-2, &.row-max-3, &.row-max-4, &.row-max-6, &.row-max-9 {
			 .views-row {
				 margin-right:0;
				 .card {
						margin-right:0;
					}
			 }
		}
		
		&.row-max-12 .views-row {
			@include span-columns(1);
			&:nth-child(12n+1) { clear:left; }
			&:nth-child(12n) { margin-right:0; }
		}
		
		&.row-max-4 .views-row {
			@include span-columns(3);
			
			width: calc(25% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(4n) { margin-right:0; }
			&:nth-child(4n+1) { clear:left;}
		}
		
		&.row-max-3 .views-row {
			@include span-columns(4);
			
			width: calc(33.3% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(3n) { margin-right:0; }
			&:nth-child(3n+1) { clear:left; }
		}
			
		&.row-max-2 .views-row {
			@include span-columns(6);

			width: calc(50% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(2n) { margin-right:0; }
			&:nth-child(2n+1) { clear:left; }

			.card-image,
			.card-photo {
				width: 50%;
				float: left;
				margin-right: 0;
				margin-bottom: $vert-space/2;
			}			
		}
				
			
		&.row-max-12 .card-more-link,
    &.row-max-12 .card-last-link-url {
			@include span-columns(1);
			
			&:nth-child(12n+1) { clear:left; }
			&:nth-child(12n) { margin-right:0; }
		}
		
		&.row-max-6 .card-more-link,
		&.row-max-6 .card-last-link-url {
			@include span-columns(2); 

			width: calc(16.66666666666667% - #{$cards-separation});
			margin-right: $cards-separation;

			&:nth-child(6n+1) { clear:left; }
			&:nth-child(6n) { margin-right:0; }
			
			&:nth-child(6n) { margin-right:0; }
			&:nth-child(6n+1) { clear:left; }
			
		}

		&.row-max-4 .card-more-link,
		&.row-max-4 .card-last-link-url {
			@include span-columns(3);
			
			width: calc(25% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(4n) { margin-right:0; }
			&:nth-child(4n+1) { clear:left; }
		}
		
		&.row-max-3 .card-more-link,
		&.row-max-3 .card-last-link-url {
			@include span-columns(4);
			
			width: calc(33.3% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(3n) { margin-right:0; }
			&:nth-child(3n+1) { clear:left; }
		}
			
		&.row-max-2 .card-more-link,
		&.row-max-2 .card-last-link-url {
			@include span-columns(6);
			width: calc(50% - #{$cards-separation});
			margin-right: $cards-separation;
			

		}
	}
	
	
	
	//If row-max-6 is selected but the page is under 960px in width, show 3 items per row instead
	@media (min-width:$narrow) and (max-width:959px) {
		&.row-max-6 .views-row {
			@include span-columns(4);
			
			width: calc(33.3% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(3n) { margin-right:0; clear:none; }
			&:nth-child(3n+1) { clear:left; }    
		 }
		}
	
	//If row-max-6 is selected but the page has a sidebar, show 3 items per row instead (in content-main and content-additional only)

	@include media ($normal) {
		&.row-max-6 .views-row {
			@include span-columns(2);
			
			width: calc(16.66% - #{$cards-separation});
			margin-right: $cards-separation;
			
			&:nth-child(6n) { margin-right:0; }
			&:nth-child(6n+1) { clear:left; }    
		 }
	}

	@media (min-width:500px) and (max-width:$normal) {
		.view:not(.view-team) {
			.card-photo {
				float:left;
			}
		}
	}
	
	
}

@include media ($normal) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.section-row:not(.view-type-slider) {
				&.row-max-6 .views-row {
					@include span-columns(4);
					&:nth-child(3n) { margin-right:0; }
					&:nth-child(3n+1) { clear:left; }
					&:nth-child(6n) { margin-right:inherit; }
					&:nth-child(6n+1) { clear:none; }    
				}
			}
		}
	}
}

//If row-max-2 is selected and the page is more than 960px in width and DONT have sidebar second, show the image on the left
@media (min-width:$normal) {	
	.sidebar-second main {
		.section-row:not(.view-type-slider) {
			&.row-max-2 .views-row,
			&.row-max-2 .card-more-link,
			&.row-max-2 .card-last-link-url {
				@include span-columns(6);

				width: calc(50% - #{$cards-separation});
				margin-right: $cards-separation;

				&:nth-child(2n) { margin-right:0; }
				&:nth-child(2n+1) { clear:left; }
				
				.card-image,
				.card-photo,
				.card-text {
					width: 100% !important;
					float: none;
				}	
			}
		}
	}
}

//If row-max-4 is selected but the page is under 1200px in width and has sidebars, show 2 items per row instead
@media (min-width:$narrow) and (max-width:$wide) {
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.section-row:not(.view-type-slider) {
				&.row-max-4 .views-row {
				@include span-columns(6);
				&:nth-child(2n) { margin-right:0; clear:none;}
				&:nth-child(2n+1) { clear:left; }    
			 }
			}
		}
	}
}
	
@media (min-width: $narrow) and (max-width:$wide) {
	.row-max-1 article:not(.node-teaser-image) {
		.card-logo,
		.card-image {
			@include span-columns(3); //2
			margin-right: 0;
			float: right;
		}
		.card-photo {
			@include span-columns(2); //2
			margin-right: 0;
			float: right;
		}
	}
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 article:not(.node-teaser-image) {
				.card-logo,
				.card-image {
					@include span-columns(3); //2
					margin-right: 0;
					float: right;
				}
				.card-photo {
					@include span-columns(2); //2
					margin-right: 0;
					float: right;
				}
			}
		}
	}
}
@media (min-width:$wide) {
	.row-max-1 article:not(.node-teaser-image) {
		.card-logo,
		.card-image {
			@include span-columns(3); //2
			margin-right: 0;
			float: right;
		}
		.card-photo {
			@include span-columns(2); //2
			margin-right: 0;
			float: right;
		}
	}
	.one-sidebar,
	.two-sidebars {
		.content-main,
		.content-additional {
			.row-max-1 article:not(.node-teaser-image) {
				.card-logo,
				.card-image {
					@include span-columns(3); //2
					margin-right: 0;
					float: right;
				}
				.card-photo {
					@include span-columns(2); //2
					margin-right: 0;
					float: right;
				}
			}
		}
	}
}

.views-row:not(.side-row) {	

	
	.view-news-events {
		@include media($narrow) {
			.view-content-main {

				.view-header {
					margin-top: 10px;
					margin-bottom: 30px;
				}
				.views-row {
					width: calc(33.333% - #{$cards-separation});
					margin-right: $cards-separation;
					
					overflow: hidden;
					&:nth-child(3n) { margin-right:0 !important; }
					&:nth-child(3n-2) { margin-left:0 !important; }
					&:nth-child(3n+1) { clear:left; }
				}
			}

			.attachment .view-content-main {
				.views-row {
					width:100%;
					margin-right: 0;
				}
			}
		}
	}
}
